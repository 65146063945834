<template>
  <v-container class="shop-cart-item pa-0">
    <v-row class="flex-nowrap" justify="space-between">
      <v-col cols="9" class="pb-0">
        <span class="quantity" data-cy="shop-cart-item-quantity"
          >{{ item.quantity }} ×
        </span>
        <span class="main-text" data-cy="shop-cart-item-name">{{
          item.offering.name
        }}</span>
      </v-col>
      <v-col cols="3" class="pb-0">
        <span class="main-text float-right" data-cy="shop-cart-item-price">{{
          item.price.value | currency(item.offering.price.baseCurrency)
        }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="hasVariants">
      <v-col class="py-2"
        ><span class="description">{{ variantsText }}</span></v-col
      >
    </v-row>
    <v-row no-gutters class="item-actions">
      <v-col class="mr-3" cols="auto"
        ><span @click="$emit('remove')" data-cy="shop-cart-item-remove">{{
          $t('remove')
        }}</span></v-col
      >
      <v-col cols="auto"
        ><span @click="$emit('edit')" data-cy="shop-cart-item-edit">{{
          $t('edit')
        }}</span></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ShopCartItem',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    variantsText() {
      let variantsText = '';
      for (let offeringGroupId in this.item.selectedOfferingGroups) {
        if (
          variantsText.length > 0 &&
          this.item.selectedOfferingGroups[offeringGroupId].length > 0
        )
          variantsText += ' • ';
        variantsText += this.item.selectedOfferingGroups[offeringGroupId]
          .map((group) => group.name)
          .join(' • ');
      }
      return variantsText;
    },

    hasVariants() {
      return Boolean(this.item.selectedOfferingGroups);
    },
  },
};
</script>

<style scoped lang="scss">
.shop-cart-item {
  .main-text {
    @include font-size(16, 24, 500);
    color: var(--v-grey9-base);
  }

  .quantity {
    color: var(--v-grey7-base);
  }

  .description {
    font-weight: normal;
    @include font-size(14, 20);
    color: var(--v-grey7-base);
  }

  .item-actions span {
    cursor: pointer;
    @include font-size(14, 150, 500);
    color: var(--v-primary-base);
  }
}
</style>
