<template>
  <v-card flat>
    <v-container>
      <v-row class="flex-nowrap">
        <v-btn
          class="button-col"
          icon
          :retain-focus-on-click="true"
          @click="decrementValue"
          :disabled="!canDecrementValue"
        >
          <v-img
            alt="minus"
            :src="canDecrementValue ? minusIcon : disabledMinusIcon"
            :height="32"
            :width="32"
          />
        </v-btn>
        <v-text-field
          :class="['font-weight-black', quantityFieldClass]"
          v-model.number="currentValue"
          @blur="validateValue"
          @click="clearInput()"
          maxlength="2"
          v-on:keypress="onKeyPress($event)"
          dense
          solo
          flat
          :disabled="!canEditTextField"
        >
        </v-text-field>
        <v-btn
          class="button-col"
          :retain-focus-on-click="true"
          @click="incrementValue"
          icon
          :disabled="!canIncrementValue"
        >
          <v-img
            alt="plus"
            :src="canIncrementValue ? plusIcon : disabledPlusIcon"
            :height="32"
            :width="32"
          />
        </v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'ShopQuantityField',
  props: {
    value: { type: Number, default: 0 },
    max: { type: Number, default: Number.MAX_VALUE },
    min: { type: Number, default: 0 },
    itemInStore: Boolean,
  },
  data() {
    return {
      currentValue: this.itemInStore ? 1 : 0,
      minusIcon: require('@/assets/minus.png'),
      plusIcon: require('@/assets/plus.png'),
      disabledMinusIcon: require('@/assets/disabled-minus.png'),
      disabledPlusIcon: require('@/assets/disabled-plus.png'),
    };
  },
  created() {
    this.$emit('load', this.currentValue);
  },
  methods: {
    setCurrentValueIfUnset() {
      if (isNaN(parseFloat(this.currentValue)) || this.currentValue < 0) {
        this.currentValue = 0;
      }
    },
    decrementValue() {
      this.setCurrentValueIfUnset();
      if (this.currentValue && this.currentValue > 0 && this.itemInStore) {
        this.currentValue = parseInt(this.currentValue, 10) - 1;
      }
    },
    incrementValue() {
      if (this.itemInStore) {
        this.setCurrentValueIfUnset();
        this.currentValue = parseInt(this.currentValue, 10) + 1;
      }
    },
    validateValue() {
      this.setCurrentValueIfUnset();
      this.validateMax();
      this.validateMin();
    },
    validateMax() {
      if (!!this.max && this.currentValue > this.max) {
        this.currentValue = this.max;
      }
    },
    validateMin() {
      if (!!this.min && this.currentValue < this.min) {
        this.currentValue = this.min;
      }
    },
    clearInput() {
      if (this.itemInStore) {
        this.currentValue = null;
      }
    },
    onKeyPress(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (
        (charCode > 31 && charCode < 48) ||
        (charCode > 57 && !this.itemInStore)
      ) {
        evt.preventDefault();
      } else {
        this.setCurrentValueIfUnset();
        if (charCode == 13) {
          this.validateValue();
        }
        this.currentValue = parseInt(this.currentValue, 10);
        return true;
      }
    },
  },
  computed: {
    canIncrementValue() {
      return isNaN(this.max) || this.currentValue < this.max;
    },
    canDecrementValue() {
      return isNaN(this.min) || this.currentValue > this.min;
    },
    canEditTextField() {
      return this.canIncrementValue || this.canDecrementValue;
    },
    quantityFieldClass() {
      if (this.currentValue && this.currentValue > 9) {
        return 'input-col-double';
      } else return 'input-col-single';
    },
  },
  watch: {
    currentValue() {
      this.$emit('input', this.currentValue);
    },
    value(value) {
      this.currentValue = value;
    },
    max(max) {
      if (max === 0) this.currentValue = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.button-col {
  height: 32px;
  width: 32px;
  z-index: 1;
  margin-top: 5px;
}
.input-col-single {
  width: 38px;
}

.input-col-double {
  width: 52px;
}
::v-deep.v-input.num-val {
  .v-input__control .v-input__slot {
    input {
      text-align: center;
      font-size: 24px;
    }
  }
}
.v-icon:before {
  background: #e3f0ff;
  color: #124399;
}
</style>
