<template>
  <v-container class="pa-0">
    <template>
      <ShopPaymentMethodsContainer
        :orderId="createdOrderId"
        :loading="isLoading || isPaymentLoading"
        :isPolling="ispollingCheckout"
        :clientConfirmationKey="clientConfirmationKey"
        @paymentMethodIdCreated="paymentMethodCreated"
        @paymentConfirmed="submitOrderPayment"
        @paymentConfirmationFailed="clearOrderCheckout"
      />
    </template>
  </v-container>
</template>

<script>
import ShopMixins from '@/modules/product/shop/mixins/ShopMixins';
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';
import ConfigsMixins from '@/core/mixins/ConfigsMixins';
import { ROUTE_NAMES } from '@/modules/product/shop/route';
import ShopPaymentMethodsContainer from '@/modules/product/shop/features/ShopCheckout/ShopPaymentMethodsContainer';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'ShopCheckoutPayment',
  mixins: [ShopMixins, ShopOrderMixins, ConfigsMixins, GoogleTagManagerMixins],
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ShopPaymentMethodsContainer,
  },
  data() {
    return {
      isPaymentLoading: false,
      isRetryingOrderCheckout: false,
      ispollingCheckout: false,
      optionalSeatFF: false,
      shouldRedirectTo: null,
    };
  },
  created() {
    this.checkOptionalSeatFF();
    this.resetPaymentFormValidations();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.hasCreatedOrder) {
        if (from.fullPath.endsWith('checkout/')) vm.$router.go(-1);
        else vm.$router.replace({ name: ROUTE_NAMES.SHOP_LANDING });
      }
      if (to.fullPath.includes('redirect_status')) {
        vm.$router.push({ name: ROUTE_NAMES.SHOP_CONFIRMATION });
      }
    });
  },
  methods: {
    async checkOptionalSeatFF() {
      this.optionalSeatFF = await this.$ldclient.variation(
        'shop-optional-seat-number',
        false,
        true
      );
      this.$ldclient.$on('change:shop-optional-seat-number', (value) => {
        this.optionalSeatFF = value;
      });
    },
    async paymentMethodCreated({
      paymentMethodId,
      requiresOrderCreation,
      paymentSource,
      shouldRedirectTo = null,
      savePaymentMethodId = false,
    }) {
      if (requiresOrderCreation || this.hasOrderCheckoutError) {
        this.isRetryingOrderCheckout = true;
        this.clearOrderState();
        await this.createOrder();
      }
      this.shouldRedirectTo = shouldRedirectTo;
      await this.submitOrderPayment(
        paymentMethodId,
        paymentSource,
        savePaymentMethodId
      );
    },
    async submitOrderPayment(
      paymentMethodId,
      paymentSource,
      savePaymentMethodId = false
    ) {
      this.isPaymentLoading = true;
      const checkoutPayload = {
        orderId: this.createdOrderId,
        paymentMethodId: paymentMethodId,
        save: savePaymentMethodId,
        discountToken: this.createdDiscountToken,
      };
      if (paymentSource && paymentSource.flow === 'redirect') {
        // redirect flow requires async checkout
        checkoutPayload.asyncCall = true;
        checkoutPayload.sourceId = paymentSource.id;
        delete checkoutPayload.paymentMethodId;
        checkoutPayload.paymentMethodType = paymentSource.type;
      }
      if (this.$route.meta && this.$route.meta.requiresPoll) {
        checkoutPayload.asyncCall = true;
      }
      this.pushToAnalytics();
      await this.checkOutOrder(checkoutPayload);
    },
    pushToAnalytics() {
      let itemsList = this.cartItems.map((item) => ({
        item_name: item.offering.name,
        item_id: item.offering.id,
        price: item.offering.price.value,
        quantity: item.quantity,
      }));
      let purchase = {
        transaction_id: this.createdOrderId,
        affiliation: this.shopTitle,
        value: this.total,
        currency: this.baseCurrency,
        items: itemsList,
      };
      this.pushPlaceOrder(purchase);
    },
    clearOrderCheckout() {
      this.isRetryingOrderCheckout = false;
      this.resetOrderCheckout();
    },
    enableOrderPolling() {
      this.ispollingCheckout = true;
      this.startCheckoutPolling({
        order: this.orderRequest,
        orderId: this.createdOrderId,
      });
    },
    goToStatusPage() {
      this.updateLastOrder(this.cartItems);
      this.ispollingCheckout = false;
      this.isPaymentLoading = false;
      this.$router.push({ name: ROUTE_NAMES.SHOP_CONFIRMATION });
    },
  },
  computed: {
    clientConfirmationKey() {
      return this.checkOutConfirmationKey;
    },
  },
  watch: {
    orderCheckoutTimeout(hasTimeout) {
      if (hasTimeout) {
        this.goToStatusPage();
      }
    },
    checkoutStatus: {
      handler(status) {
        if (status == null) return;
        if (this.shouldRedirectTo) {
          const redirect = this.shouldRedirectTo;
          this.shouldRedirectTo = null;
          window.location = redirect;
          this.clearCart();
        } else if (this.isAsyncCheckout) {
          return;
        } else if (status === 'Confirmed' || status === 'Pending') {
          this.goToStatusPage();
          this.clearCart();
        } else {
          this.isRetryingOrderCheckout = true;
          this.clearOrderState();
          this.createOrder();
          this.clearCart();
        }
      },
    },
    isAsyncCheckout: {
      immediate: true,
      handler(newValue) {
        if (!!newValue) {
          this.enableOrderPolling();
        }
      },
    },
    hasCreatedOrder(newValue) {
      if (newValue) {
        this.submitOrderPayment();
      }
    },
    hasOrderCreationError(newValue) {
      if (newValue) {
        this.isRetryingOrderCheckout = false;
      }
    },
    hasOrderCheckoutError(newValue) {
      if (newValue) {
        this.isRetryingOrderCheckout = false;
        this.ispollingCheckout = false;
      }
    },
  },
};
</script>

<style scoped></style>
