import { render, staticRenderFns } from "./ShopCheckoutConfirmationModal.vue?vue&type=template&id=2ee0aa16&scoped=true&"
import script from "./ShopCheckoutConfirmationModal.vue?vue&type=script&lang=js&"
export * from "./ShopCheckoutConfirmationModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ee0aa16",
  null
  
)

export default component.exports