<template>
  <v-container fluid class="pa-0">
    <ShopCheckoutModal
      :open="dialogMode"
      :is-loading="isLoading"
      :checkout-state="checkoutState"
      :enable-form-submit-button="isInfoFormValid"
      @backToMenu="onBackToMenu"
    />
    <v-row>
      <v-col cols="12" sm="8">
        <v-container class="pa-0">
          <v-row dense justify="space-between" align="end">
            <v-col cols="auto">
              <h1 class="checkout-title">{{ $t('shop.checkout') }}</h1>
            </v-col>
            <v-col cols="auto">
              <ShopCheckoutStatesStepper
                @goBackToForm="goToForms"
                :state="checkoutState"
              />
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col class="pt-6">
              <span class="information-title">{{ subtitleText }}</span>
            </v-col>
          </v-row>
          <v-row v-if="hasPaymentError">
            <v-col cols="12">
              <v-alert dense outlined type="error">
                <v-row
                  v-if="isPartnerOfferingsStoreClosed || hasOrderCheckoutError"
                  no-gutters
                  align="center"
                >
                  <v-col cols="auto">
                    {{ orderPaymentError }}
                    <router-link
                      class="size16-weight400 pl-1 btn-return"
                      v-if="isItemOutOfStock && !isPartnerOfferingsStoreClosed"
                      :to="{ name: 'shop-landing' }"
                    >
                      {{ shopTitle }}.
                    </router-link>
                    <router-link
                      class="size16-weight400 pl-1 btn-return"
                      v-else
                      to="/"
                    >
                      {{ $t('product.go_to_homepage') }}
                    </router-link>
                  </v-col>
                </v-row>
                <template v-else>
                  {{ orderPaymentError || stripeError }}
                </template>
              </v-alert>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12">
              <router-view></router-view>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="4" v-if="$vuetify.breakpoint.mdAndUp">
        <v-container class="pa-0">
          <v-row no-gutters>
            <v-col>
              <span class="order-title">{{ $t('shop.your_order') }}</span>
            </v-col>
          </v-row>
          <v-row v-if="hasContextFlights" class="cart-flights-info">
            <v-col
              ><span>{{
                $t('shop.for_flight', {
                  flightAirportCodes,
                  flightNumber: selectedContextFlight.flightNumber,
                })
              }}</span></v-col
            >
          </v-row>
          <v-row>
            <v-col cols="12">
              <ShopCheckoutOrderSummary />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import ShopCheckoutOrderSummary from '@/modules/product/shop/features/ShopCheckout/ShopCheckoutOrderSummary';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import ShopCheckoutModal from '@/modules/product/shop/features/ShopCheckout/ShopCheckoutModal';
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';
import { ROUTE_NAMES } from '@/modules/product/shop/route';
import ShopCheckoutStatesStepper from '@/modules/product/shop/features/ShopCheckout/ShopCheckoutStatesStepper';

export default {
  name: 'ShopCheckout',
  components: {
    ShopCheckoutStatesStepper,
    ShopCheckoutModal,
    ShopCheckoutOrderSummary,
  },
  mixins: [FlightsMixins, SelectedContextFlightMixins, ShopOrderMixins],

  data() {
    return {
      dialogMode: false,
      isLoading: false,
      checkoutState: 'forms',
    };
  },

  watch: {
    ['$vuetify.breakpoint.smAndDown']: {
      immediate: true,
      handler(smAndDown) {
        this.dialogMode = smAndDown;
      },
    },

    hasPaymentError: {
      immediate: true,
      handler(newVal, val) {
        if (!!newVal) {
          this.isLoading = false;
        }
      },
    },

    hasCreatedOrder: {
      handler(val) {
        if (val) {
          if (
            !this.isPaymentState ||
            this.$route.name !== ROUTE_NAMES.SHOP_CHECKOUT_PAYMENT
          ) {
            this.goToPayment();
            this.isLoading = false;
          }
        }
      },
    },

    $route: {
      immediate: true,
      handler(val) {
        if (val.name === ROUTE_NAMES.SHOP_CHECKOUT) {
          this.checkoutState = 'forms';
        } else {
          this.checkoutState = 'payment';
        }
      },
    },
  },

  computed: {
    subtitleText() {
      return this.isPaymentState
        ? this.$t('product.steps.names.review_payment')
        : this.$t('product.steps.names.your_information');
    },

    isPaymentState() {
      return this.checkoutState === 'payment';
    },

    hasPaymentError() {
      return this.hasOrderCreationError || this.hasOrderCheckoutError;
    },

    orderPaymentError() {
      if (this.hasOrderCreationError) {
        return this.orderCreation.error;
      } else if (this.hasOrderCheckoutError) {
        return this.orderCheckout.error;
      } else {
        return null;
      }
    },
  },

  methods: {
    goToForms() {
      if (this.$route.name === ROUTE_NAMES.SHOP_CHECKOUT_PAYMENT) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: ROUTE_NAMES.SHOP_CHECKOUT });
      }
    },

    goToPayment() {
      this.isLoading = false;
      this.updateLastOrder(this.cartItems);
      this.$router.push({ name: ROUTE_NAMES.SHOP_CHECKOUT_PAYMENT });
    },

    onBackToMenu() {
      if (this.checkoutState === 'payment') this.$router.go(-2);
      else if (this.checkoutState === 'forms') this.$router.go(-1);
      else this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.information-title {
  font-weight: bold;
  font-size: 18px;
  color: var(--v-grey8-base);
}

.checkout-title {
  @include font-size(36, 150, 300);
  color: var(--v-grey9-base);
}

.order-title {
  @include font-size(24, 150, 300);
  color: var(--v-grey9-base);
}

.btn-return {
  text-decoration: underline;
  color: var(--v-error-base);
}
</style>
