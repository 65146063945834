<template>
  <v-container>
    <v-row class="checkout-order-details">
      <v-col cols="4">
        <span class="font-weight-bold">{{ $t('shop.info') }}</span>
      </v-col>
      <v-col cols="8">
        <span class="d-block pb-2">
          {{ customer.firstName }} {{ customer.lastName }}
        </span>
        <span class="d-block pb-2">{{ customer.email }}</span>
        <span
          v-if="!!customer.seatColumn && !!customer.seatRow"
          class="d-block pb-2"
        >
          {{
            $t('shop.seat_number', {
              seatNum: customer.seatRow + customer.seatColumn,
            })
          }}
        </span>
        <span v-if="!!customer.pnr" class="d-block">
          {{ pnrTitle }}: {{ customer.pnr }}
        </span>
      </v-col>
    </v-row>
    <v-row class="checkout-order-details">
      <v-col cols="4">
        <span class="font-weight-bold">{{ $t('shop.order_id') }}</span>
      </v-col>
      <v-col cols="8">
        <span class="d-block">{{ orderReferenceId }}</span>
      </v-col>
    </v-row>
    <v-row class="checkout-order-details">
      <v-col cols="4">
        <span class="font-weight-bold">{{ $t('shop.order_date') }}</span>
      </v-col>
      <v-col cols="8">
        <span class="d-block">{{ orderDate }}</span>
      </v-col>
    </v-row>
    <v-row class="checkout-order-details">
      <v-col cols="4">
        <span class="font-weight-bold">{{ $t('shop.paid_with') }}</span>
      </v-col>
      <v-col cols="8">
        <span class="d-flex" v-if="isCreditCard">
          <span class="d-inline-block">
            <v-img alt="visaIcon" width="38" :src="visaIcon" />
          </span>
          ******{{ creditCardLastDigits }}
        </span>
        <span class="d-flex" v-else>
          {{ paymentType }}
        </span>
      </v-col>
    </v-row>
    <v-row v-if="!$vuetify.breakpoint.mdAndUp" class="checkout-order-details">
      <v-col cols="12">
        <ShopCheckoutOrderSummary />
      </v-col>
    </v-row>

    <v-row v-if="showTotal" class="checkout-order-details">
      <v-col cols="4">
        <span class="font-weight-bold">{{ $t('shop.total') }}</span>
      </v-col>
      <v-col cols="8">
        <span class="d-block">{{ total | currency(baseCurrency) }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShopCheckoutOrderSummary from '@/modules/product/shop/features/ShopCheckout/ShopCheckoutOrderSummary';
import ConfigsMixins from '@/core/mixins/ConfigsMixins';

export default {
  name: 'ShopCheckoutOrderDetails',
  components: { ShopCheckoutOrderSummary },
  mixins: [ConfigsMixins],
  props: {
    customer: {
      type: Object,
      default: () => ({
        firstName: 'John',
        lastName: 'Maus',
        email: 'email@mail.com',
      }),
    },
    orderReferenceId: {
      type: String,
      default: '555454545',
    },
    supplierReferenceId: {
      type: String,
      default: '555454545',
    },
    orderDate: {
      type: String,
      default: '555454545',
    },
    creditCardLastDigits: {
      type: String,
      default: '555454545',
    },
    isCreditCard: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    baseCurrency: {
      type: String,
      default: 'USD',
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
    paymentType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visaIcon: require('@/assets/visa.svg'),
    };
  },
};
</script>

<style scoped lang="scss">
.checkout-order-details {
  background-color: var(--v-grey1-base);
}
</style>
