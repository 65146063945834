<template>
  <v-card
    :class="[
      {
        'my-3': !$vuetify.breakpoint.smAndDown,
        'pa-3': $vuetify.breakpoint.smAndDown,
        'px-0': isSearchResultItem,
      },
    ]"
    :ref="`${item.id}-card`"
    :elevation="0"
    @click="onClick"
    :disabled="isSoldOut"
  >
    <v-row
      justify="space-between"
      :ref="item.id"
      no-gutters
      class="shop-category-list-item"
      data-cy="category-item-component"
    >
      <v-col cols="9" sm="7" md="9">
        <v-container class="item-container">
          <span class="d-block item-name">{{ item.name || item.title }}</span>
          <span class="d-block item-subtext">{{ item.description }}</span>
          <span
            v-if="!(hasVariants && item.price.value === 0)"
            class="current-price mr-2"
            >{{
              item.price
                ? item.price.value
                : item.price_value
                  | currency(
                    item.price ? item.price.baseCurrency : item.price_currency
                  )
            }}</span
          >
          <span class="d-inline original-price" v-if="hasOriginalPrice"
            >{{ originalPrice.body | currency(item.price.baseCurrency) }}
          </span>
          <div class="d-inline item-subtext" v-if="isSoldOut">
            <span> · </span><span class="error--text">Sold out</span>
          </div>
          <div class="d-inline item-subtext" v-if="itemInCart.status">
            <span> · </span
            ><span class="info--text">{{
              $t('shop.quantity_in_cart', { qty: itemInCart.qty })
            }}</span>
          </div>
          <div
            class="d-inline item-subtext"
            v-if="hasLowQuantity && !isSoldOut"
          >
            <span> · </span
            ><span class="warning--text">{{
              $t('shop.quantity_remaining', { qty: item.availableQuantity })
            }}</span>
          </div>
        </v-container>
      </v-col>
      <v-col cols="auto" v-if="item.image || item.thumbnail">
        <v-img
          :alt="`thumbnail image for ${item.name || item.title}`"
          class="item-img"
          :class="{ 'search-result': isSearchResultItem }"
          :src="item.image || item.thumbnail"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';
import ShopMixins from '@/modules/product/shop/mixins/ShopMixins';
import { ROUTE_NAMES } from '@/modules/product/shop/route';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
export default {
  name: 'ShopCategoryListItem',
  mixins: [ShopOrderMixins, ShopMixins, GoogleTagManagerMixins],
  props: {
    isSearchResultItem: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    originalPrice() {
      return this.item.information?.find(
        (info) => info.title === 'original_price'
      );
    },
    hasOriginalPrice() {
      return Boolean(this.originalPrice);
    },
    isSoldOut() {
      return (
        this.item.availableQuantity - (this.itemInCart.qty || 0) == 0 ||
        this.item.isAvailable == false
      );
    },
    hasLowQuantity() {
      return this.item.availableQuantity <= 3;
    },
    hasVariants() {
      return Boolean(this.item?.offeringGroups?.length > 0);
    },
    itemInCart() {
      let foundItems = [];
      if (this.hasCartItems) {
        foundItems = this.cartItems.filter(
          (x) => x.offering.id === this.item.id
        );
      }
      if (foundItems && foundItems.length) {
        const totalNumberofOfferingsInCart = foundItems.reduce(
          (acc, o) => acc + o.quantity,
          0
        );
        return {
          status: true,
          qty: totalNumberofOfferingsInCart,
        };
      } else {
        return {
          status: false,
          qty: 0,
        };
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (!!val && val.redirectedFrom) {
          let offeringId = val.redirectedFrom.split('/').pop();
          if (!!offeringId && offeringId == this.item.id) {
            this.clearHighlightedOffering();
            this.highlightOffering(offeringId);
            setTimeout(() => {
              this.$router
                .push({
                  name: ROUTE_NAMES.SHOP_LANDING,
                  query: {
                    offeringId: offeringId,
                  },
                })
                .catch(() => {});
            });
          }
        }
      },
    },
  },
  methods: {
    onClick() {
      this.pushToAnalytics();
      this.$emit('click', this.item);
    },
    pushToAnalytics() {
      let items = [
        {
          item_name: this.item?.name,
          item_id: this.item?.id,
          price: this.item?.price?.value,
          quantity: this.item?.availableQuantity,
        },
      ];
      this.pushProductClick(items);
    },
  },
};
</script>

<style scoped lang="scss">
.shop-category-list-item {
  @media (min-width: map-get($grid-breakpoints, sm)) {
    border: 1px solid var(--v-grey4-base);
    box-sizing: border-box;
    border-radius: 4px;
    max-height: 140px;
  }
  overflow: hidden;
  max-height: 100px;

  .item-container {
    padding: 0;
    @media (min-width: map-get($grid-breakpoints, sm)) {
      padding: 12px;
    }
  }

  .item-img {
    border-radius: 4px;
    @media (min-width: map-get($grid-breakpoints, sm)) {
      border-radius: 0px;
    }

    width: 72px;
    height: 72px;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      width: 100px;
      height: 100px;
    }

    @media (min-width: map-get($grid-breakpoints, md)) {
      width: 140px;
      height: 140px;
    }

    &.search-result {
      width: 60px;
      height: 60px;

      @media (min-width: map-get($grid-breakpoints, sm)) {
        width: 93px;
        height: 93px;
      }
    }
  }
  .item-name {
    color: var(--v-grey9-base);
    @include font-size(16, 24, 600);

    @media (min-width: map-get($grid-breakpoints, sm)) {
      line-height: 150%;
    }
  }
  .item-subtext {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include font-size(14, 20);

    @media (min-width: map-get($grid-breakpoints, sm)) {
      line-height: 150%;
    }
  }

  .original-price {
    font-size: 14px;
    font-weight: 400;
    color: var(--v-grey7-base);
    text-decoration: line-through;
  }
  .current-price {
    font-size: 14px;
    font-weight: 400;
    color: var(--v-grey8-base);
  }
}
</style>
