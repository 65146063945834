<template>
  <div
    v-intersect="{
      handler: onIntersect,
      options: {
        threshold: intersectThreshold,
        rootMargin: intersectRootMargin,
      },
    }"
    class="mx-auto"
    max-width="336"
  >
    <v-container
      :ref="categoryInfo.slug"
      fluid
      class="shop-category-list px-0"
      data-cy="category-component"
      :class="{ 'pa-0': $vuetify.breakpoint.mdAndUp }"
    >
      <div class="mobile-divider" v-if="$vuetify.breakpoint.smAndDown"></div>
      <v-row>
        <v-col
          cols="12"
          class="pb-0"
          :class="{ 'px-0': $vuetify.breakpoint.mdAndUp }"
        >
          <span class="d-block category-name">{{ categoryInfo.name }}</span>
          <v-divider v-if="$vuetify.breakpoint.mdAndUp" class="mt-3" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-0">
          <v-container class="pa-0">
            <template v-for="(item, index) in categoryInfo.offerings">
              <ShopCategoryListItem
                @click="onItemClick"
                :item="item"
                :key="item.id"
              />
              <v-divider
                v-if="
                  $vuetify.breakpoint.smAndDown &&
                    index !== categoryInfo.offerings.length - 1
                "
                :key="`divider-${item.id}`"
              />
            </template>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ROUTE_NAMES } from '@/modules/product/shop/route';
import ShopCategoryListItem from '@/modules/product/shop/features/ShopCategory/ShopCategoryListItem';
import ShopMixins from '@/modules/product/shop/mixins/ShopMixins';

export default {
  name: 'ShopCategoryList',
  components: { ShopCategoryListItem },
  mixins: [ShopMixins],
  props: {
    categoryInfo: Object,
    activeTab: Object,
    clickedFromMenu: Boolean,
    order: Number,
  },

  data() {
    return {
      preselected: '',
      intersectThreshold: [0, 0.3],
      intersectRootMargin: this.$vuetify.breakpoint.mdAndUp
        ? '-300px 0px 0px 0px'
        : '-250px 0px 0px 0px',
    };
  },

  beforeDestroy() {
    this.clearSelectedCategory();
  },

  methods: {
    scrollToCategory(refName) {
      let element = this.$refs[refName];
      if (!!element) {
        const y =
          element.getBoundingClientRect().top +
          window.pageYOffset -
          (this.$vuetify.breakpoint.mdAndDown
            ? this.$vuetify.breakpoint.smAndDown
              ? 160
              : 250
            : 270);
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    },

    onItemClick(item) {
      this.$router.push({
        name: ROUTE_NAMES.SHOP_PRODUCT_DETAILS,
        params: {
          id: this.$route.params.id,
          offeringId: item.id,
        },
      });
    },

    onIntersect(entries, observer, isIntersecting) {
      if (entries[0].isIntersecting && !this.clickedFromMenu) {
        this.$emit('active', this.categoryInfo.slug, this.order);
      }
      if (!entries[0].isIntersecting && !this.clickedFromMenu) {
        this.$emit('remove', this.categoryInfo.slug, this.order);
      }
    },
  },

  mounted() {
    if (
      !!this.selectedCategory &&
      this.categoryInfo.slug === this.selectedCategory
    ) {
      setTimeout(() => {
        this.scrollToCategory(this.selectedCategory);
      });
    }
  },

  watch: {
    ['$route.query.tagGroup']: {
      immediate: true,
      handler(tagGroup) {
        if (!!tagGroup && tagGroup === this.categoryInfo.slug) {
          this.preselected = tagGroup;
          this.selectCategory(tagGroup);
        }
      },
    },

    selectedCategory(val) {
      if (!!val && this.categoryInfo.slug === val) {
        this.scrollToCategory(val);
      }
    },

    activeTab: {
      handler(val) {
        if (!!val && this.categoryInfo.slug === val.id) {
          //this is to cover the scenario when user scrolls away from selectedCategory and then clicks back on selectedCategory.
          if (val.id === this.selectedCategory) this.scrollToCategory(val.id);
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.shop-category-list {
  .mobile-divider {
    height: 2px;
    width: 60px;
    background-color: var(--v-primary-base);
  }

  .category-name {
    @include font-size(20, 30, 300);
    color: var(--v-grey9-base);
  }
}
</style>
