<template>
  <v-container class="py-0" style="height: 100%">
    <v-row>
      <template v-if="isLoadingListingsForTheFirstTime && !isCategoriesEmpty">
        <v-col cols="12">
          <v-skeleton-loader type="heading" class="mb-4"></v-skeleton-loader>
          <v-skeleton-loader type="heading" class="mb-4"></v-skeleton-loader>
          <v-skeleton-loader type="card"></v-skeleton-loader>
          <v-skeleton-loader type="card"></v-skeleton-loader>
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
      </template>

      <template v-else>
        <v-col
          class="pa-0 sticky-menu-bar"
          :class="{
            'without-flight': !hasSelectedContextFlight,
          }"
          @scroll="handleScroll"
        >
          <v-row no-gutters>
            <v-col
              id="ShopSearch"
              cols="12"
              class="d-flex justify-space-between align-center"
              :class="[
                isSearchActive ? 'pb-0' : 'pb-2',
                {
                  'px-3': $vuetify.breakpoint.smAndDown,
                },
              ]"
            >
              <template v-if="isSearchActive">
                <ShopSearchField
                  @startSearch="startSearch"
                  @closeSearch="closeSearch"
                  @clearSearch="clearSearch"
                  :isCategoryListPage="true"
                />
              </template>
              <template v-else>
                <div class="d-inline">
                  <span data-cy="shop-store-title" class="shop-title">{{
                    shopTitle
                  }}</span>
                  <span
                    v-if="!!categoryWithAssociatedOfferings.length"
                    data-cy="shop-store-status"
                    class="mx-3"
                    :class="{
                      'success--text': isOpen,
                      'error--text': !isOpen,
                    }"
                  >
                    {{ shopStatusText }}
                  </span>
                </div>
                <template v-if="searchFF && isOpen">
                  <v-btn
                    type="button"
                    text
                    small
                    icon
                    @click="toggleSearch"
                    width="20"
                    height="20"
                    aria-label="open search"
                    title="open search"
                  >
                    <v-img
                      aria-hidden="true"
                      contain
                      :src="searchIcon"
                      alt="search icon"
                    />
                  </v-btn>
                </template>
              </template>
            </v-col>

            <v-col
              v-if="!!categoryWithAssociatedOfferings.length"
              class="pa-0 top-block"
              cols="12"
            >
              <CategoryMenuBar
                v-if="!!allCategories && allCategories.length > 1"
                @click="onMenuItemClick"
                :menuItems="allFormattedCategories"
                :hasSelectedContextFlight="hasSelectedContextFlight"
                :activeTabId="activeTabId"
              />
              <template v-if="isSearchActive">
                <div class="gl-search-container"></div>
              </template>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="shop-list-wrapper">
          <ShopCategories
            @scroll="handleScroll"
            @active="addToActive"
            @remove="removeFromActive"
            :clickedMenu="isClickedFromMenu"
            :activeTab="activeTabId"
            :category-list="categoryWithAssociatedOfferings"
          />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import CategoryMenuBar from '@/core/components/CategoryMenuBar.vue';
import ShopCategories from '@/modules/product/shop/features/ShopCategory/ShopCategories';
import ShopMixins from '@/modules/product/shop/mixins/ShopMixins';
import OfferingListingMixins from '@/modules/product/shop/mixins/OfferingListingMixins';
import { ROUTE_NAMES } from '@/modules/product/shop/route';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import ShopSearchField from '@/modules/product/shop/ShopSearchField';

export default {
  name: 'CategoriesListPage',
  components: {
    ShopCategories,
    CategoryMenuBar,
    ShopSearchField,
  },
  mixins: [ShopMixins, OfferingListingMixins, FlightsMixins],

  data() {
    return {
      searchIsOpen: false,
      isLoadingListingsForTheFirstTime: true,
      isSearchActive: false,
      searchIcon: require('@/assets/searchIcon.svg'),
      queryModel: null,
      searchFF: false,
      scrollPosition: null,
      currentMenuItem: null,
      activeBlocks: [],
      isClickedFromMenu: false,
    };
  },

  computed: {
    shopStatusText() {
      if (this.isOpen == null) {
        return this.isOpen;
      } else
        return this.isOpen
          ? this.$t('shop.shop_open')
          : this.$t('shop.shop_closed');
    },

    activeTabId() {
      return this.activeBlocks.length > 0 ? this.activeBlocks[0] : null;
    },

    allCategories() {
      if (this.hasCategories) {
        return this.categories.categories;
      } else return [];
    },

    allFormattedCategories() {
      return this.hasCategories
        ? this.formatCategories(this.allCategories)
        : null;
    },

    isCategoriesEmpty() {
      return (
        !!this.categories.categories && this.categories.categories.length === 0
      );
    },
  },

  destroyed() {
    localStorage.removeItem('scrollPosition');
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    disableScrolling() {
      const y = window.scrollY;

      if (this.$vuetify.breakpoint.smAndDown) {
        if (window.top.scrollY >= 106.5) {
          document.body.style.cssText =
            'position: fixed; width: 100%;top: -106.5px;';
        } else {
          document.body.style.cssText = `position: fixed; width: 100%;top: -${y}px;`;
        }
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        if (window.top.scrollY >= 112) {
          document.body.style.cssText =
            'position: fixed; width: 100%;top: -112px;';
        } else {
          document.body.style.cssText = `position: fixed; width: 100%;top: -${y}px;`;
        }
      } else {
        document.body.style.cssText = `position: fixed; width: 100%;top: 0;`;
      }

      const body = document.getElementsByClassName('shop-list-wrapper')[0];
      if (!!body) {
        let top = y;
        if (this.$vuetify.breakpoint.smAndDown) {
          top = y - 106.5;
        } else if (this.$vuetify.breakpoint.mdAndDown) {
          top = y - 112;
        }

        body.style.position = 'relative';
        body.style.top = `-${top}px`;
      }
    },

    enableScrolling() {
      const categoriesWrapper = document.getElementsByClassName(
        'shop-list-wrapper'
      );

      Array.prototype.forEach.call(categoriesWrapper, (item) =>
        item.removeAttribute('style')
      );

      document.body.removeAttribute('style');
    },

    goToProduct() {
      window.scrollTo({
        top: localStorage.getItem('scrollPosition'),
        behavior: 'instant',
      });
    },

    handleScroll() {
      this.scrollPosition = window.scrollY;
      if (!!this.isClickedFromMenu) {
        setTimeout(() => {
          this.isClickedFromMenu = false;
        }, 500);
      }
    },

    formatCategories(categories) {
      return categories.map((category) => {
        return {
          title: category.name,
          id: category.slug,
        };
      });
    },

    async checkSearchFF() {
      this.searchFF = await this.$ldclient.variation(
        'shop-search',
        false,
        true
      );
      this.$ldclient.$on('change:shop-search', (value) => {
        this.searchFF = value;
      });
    },

    onMenuItemClick(refName) {
      let orderOfClickedBlock = this.categories.categories
        .map((category) => {
          return category.slug;
        })
        .indexOf(refName);

      this.isClickedFromMenu = true;
      this.activeBlocks = [...[], { id: refName, order: orderOfClickedBlock }];
      this.selectCategory(refName);
    },

    removeFromActive(block, order) {
      let indexOfBlock = this.activeBlocks
        .map((category) => category.id)
        .indexOf(block);

      if (indexOfBlock !== -1) {
        this.activeBlocks.splice(indexOfBlock, 1);
      }
    },

    addToActive(block, order) {
      if (
        this.activeBlocks.filter((category) => category.id === block).length > 0
      )
        return;

      //keeps this.activeBlocks items consecutive in case we missed adding one item
      if (this.activeBlocks.length > 0) {
        if (
          order > this.activeBlocks[this.activeBlocks.length - 1].order + 1 ||
          order < this.activeBlocks[0].order - 1
        ) {
          this.activeBlocks = [];
        }
      }

      this.activeBlocks = [...this.activeBlocks, { id: block, order: order }];
      this.activeBlocks.sort((a, b) => a.order - b.order);
    },

    closeSearch() {
      this.hasSearchResults = false;
      this.clearSearch();
      if (this.searchIsOpen) {
        this.enableScrolling();
        this.searchIsOpen = false;
        this.$emit('openSearch', this.searchIsOpen);
      }
      this.isSearchActive = false;
      this.goToProduct();
    },

    clearSearch() {
      this.queryModel = null;
      if (!this.searchIsOpen) {
        this.$emit('openSearch', !this.searchIsOpen);
      }
    },

    toggleSearch() {
      this.isSearchActive = true;
      this.searchIsOpen = !this.searchIsOpen;
      localStorage.setItem('scrollPosition', this.scrollPosition);
      this.$emit('openSearch', !this.hasSearchResults);
      this.disableScrolling();
    },

    startSearch(val) {
      if (!!val) {
        this.enableScrolling();
        this.$router.push({
          name: ROUTE_NAMES.SHOP_CATEGRIES_SEARCH,
          query: {
            q: val,
          },
        });
      } else {
        this.closeSearch();
      }
    },
  },

  created() {
    this.checkSearchFF();
    window.addEventListener('scroll', this.handleScroll);
  },

  watch: {
    isSearchActive(val) {
      if (!val) {
        this.enableScrolling();
      }
    },

    listingsAreLoading: {
      immediate: true,
      handler(val, oldVal) {
        if (val === false && oldVal === true)
          this.isLoadingListingsForTheFirstTime = false;
      },
    },

    selectedCategory: {
      immediate: true,
      handler(val) {
        if (!!val) {
          setTimeout(() => {
            this.isClickedFromMenu = false;
          }, 1000);
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.gl-search-container {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  z-index: 3;
  overflow: hidden;
}

.shop-title {
  @include font-size(24, 150, 300);
  color: var(--v-grey9-base);
}

.top-block {
  position: relative;
}

.sticky-menu-bar {
  background: #ffffff;
  z-index: 5;
  padding-top: 14px !important;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    padding-top: 26px !important;
  }
}
</style>
