<template>
  <v-container fluid class="pa-0">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </v-container>
</template>

<script>
import ShopMixins from './mixins/ShopMixins.vue';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import * as Sentry from '@sentry/vue';
export default {
  name: 'Shop',
  mixins: [ShopMixins, FlightsMixins],
  data() {
    return {
      showFlightLandingFF: false,
    };
  },
  created() {
    Sentry.setTag('module', 'shop');
    this.registerStore();
  },
  watch: {
    selectedContextFlight: {
      immediate: true,
      handler(val, oldVal) {
        this.checkFlightLandingFF();
        if (!val && !this.showFlightLandingFF) {
          this.$router.push({ name: 'concierge-discover' });
        }
      },
    },
  },
  methods: {
    async checkFlightLandingFF() {
      this.showFlightLandingFF = await this.$ldclient.variation(
        'flights-landing-page',
        false,
        true
      );
      this.$ldclient.$on('change:flights-landing-page', (value) => {
        this.showFlightLandingFF = value;
      });
    },
  },
};
</script>
