<template>
  <v-container
    @scroll="isScrolled"
    class="product-page"
    :class="{ 'px-0': $vuetify.breakpoint.mdAndDown }"
  >
    <v-row
      no-gutters
      class="mb-3 navigation"
      :class="{ 'px-1': $vuetify.breakpoint.mdAndDown }"
    >
      <v-col cols="auto">
        <router-link
          class="ml-2 ml-md-0 navigation-text"
          :to="{ name: 'shop-landing' }"
        >
          <v-icon size="18" class="navigation-icon"> mdi-arrow-left </v-icon>
          {{ $t('back_link_text') }}
        </router-link>
      </v-col>
    </v-row>
    <v-row no-gutters justify="space-between">
      <v-col
        cols="12"
        md="7"
        class="pr-md-5"
        :class="{ 'px-3': $vuetify.breakpoint.mdAndDown }"
        id="offering-variants-mobile"
      >
        <ImageSliderComponent
          class="product-image"
          :max-height="maxImgHeight"
          :image-urls="offeringItemDetails.images"
        />
        <h1 class="product-title mb-4">
          {{ offeringItemDetails.name }}
        </h1>

        <template
          v-if="!(hasVariants && offeringItemDetails.price.value === 0)"
        >
          <p class="current-price mb-0">
            {{
              offeringItemDetails.price.value
                | currency(offeringItemDetails.price.baseCurrency)
            }}
          </p>
        </template>

        <template v-if="hasOriginalPrice">
          <p class="original-price mb-0">
            {{ originalPrice.body | currency(price.baseCurrency) }}
          </p>
        </template>

        <template v-if="hasReducedAmount">
          <p class="save-price mb-0">
            {{ $t('shop.you_save') }}
            {{
              (originalPrice.body - offeringItemDetails.price.value)
                | currency(price.baseCurrency)
            }}
          </p>
        </template>

        <template v-if="offeringItemDetails.description">
          <p class="mt-3">
            {{ offeringItemDetails.description }}
          </p>
        </template>

        <template v-if="containsInformation">
          <p
            class="pt-2"
            v-for="(info, key) in filteredOfferingsInformation"
            :key="key"
          >
            <template v-if="info.title">
              <span class="d-block font-weight-bold">
                {{ info.title }}
              </span>
            </template>

            <template v-if="info.body">
              <span class="d-block">{{ info.body }}</span>
            </template>
          </p>
        </template>
        <OfferingVariantsGroups
          v-if="hasVariants && $vuetify.breakpoint.mdAndDown"
          :offering-groups="offeringItemDetails.offeringGroups"
          :show-error="showVariantsError"
          :variants-selection="variantsSelection"
          @change="onVariantsChange"
          ref="offering-variants-mobile"
        />
      </v-col>
      <v-col cols="12" md="5" class="right-side">
        <v-row no-gutters class="additional" @scroll="isScrolled">
          <v-col
            cols="12"
            class="px-4 offering-variants"
            :class="{ scroll: showMore }"
          >
            <template v-if="hasVariants && $vuetify.breakpoint.lgAndUp">
              <OfferingVariantsGroups
                :offering-groups="offeringItemDetails.offeringGroups"
                :show-error="showVariantsError"
                :variants-selection="variantsSelection"
                @change="onVariantsChange"
                :isProductPage="true"
                ref="offering-variants"
              />
            </template>
          </v-col>
          <template v-if="showMore">
            <div class="show-more" @click="scrollToBottom">
              {{ $t('shop.more_options') }}
              <v-icon size="14" color="var(--v-grey8-base)">
                mdi-arrow-down
              </v-icon>
            </div>
          </template>

          <div class="additional-bottom">
            <v-col cols="12" class="pa-0">
              <v-row
                no-gutters
                align="center"
                justify="space-between"
                class="additional-quantity"
                :class="{ 'has-shadow': overHeight }"
              >
                <v-col cols="4" class="py-3 pl-sm-3">
                  <NumberInput
                    v-model="quantity"
                    :min="1"
                    :max="maxQuantity"
                    class="d-flex flex-row"
                  />
                </v-col>
                <v-col cols="auto" class="pr-sm-3 price text-center">
                  {{ price.value | currency(price.baseCurrency) }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-btn
                    height="58px"
                    data-cy="add-to-cart-button"
                    tile
                    depressed
                    block
                    :disabled="!isOpen"
                    @click="onSubmit"
                    color="primary"
                  >
                    {{ actionButtonText }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ROUTE_NAMES } from '@/modules/product/shop/route';
import OfferingVariantsGroups from '@/modules/product/shop/features/OfferingVariants/OfferingVariantsGroups';
import NumberInput from '@/core/components/NumberInput';
import ImageSliderComponent from '@/modules/common/components/ImageSliderComponent';
import OfferingListingMixins from '@/modules/product/shop/mixins/OfferingListingMixins';
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import HotJarMixins from '@/core/mixins/HotJarMixins';

export default {
  name: 'ShopProductPage',
  mixins: [
    OfferingListingMixins,
    ShopOrderMixins,
    GoogleTagManagerMixins,
    HotJarMixins,
  ],
  components: { OfferingVariantsGroups, NumberInput, ImageSliderComponent },
  props: {
    offeringItemDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      priceValue: 0,
      showVariantsError: false,
      variantsSelection: {},
      quantity: 1,
      productInfo: null,
      productId: this.$route.params.offeringId,
      orderId: this.$route.params.orderId,
      overHeight: false,
      showMore: false,
      quantityBeforeEditing: null,
    };
  },
  mounted() {
    let divOne = null;
    let divTwo = null;

    if (
      window.matchMedia('(min-width: 769px)').matches &&
      Boolean(this.$refs['offering-variants'])
    ) {
      divOne = this.$refs['offering-variants'].$el;

      if (Boolean(divOne) && divOne.offsetHeight > 200) {
        this.overHeight = true;
        this.isScrolled();
      } else {
        this.showMore = false;
      }
    } else {
      if (this.hasVariants && Boolean(this.$refs['offering-variants-mobile'])) {
        divTwo = document.getElementsByClassName('product-page')[0];
        if (
          window.matchMedia('(max-width: 768px)').matches &&
          divTwo.offsetHeight >
            window.outerHeight -
              (this.$vuetify.breakpoint.smAndDown ? 340 : 385)
        ) {
          this.showMore = true;
          window.addEventListener('scroll', this.isScrolledMobile);
        } else {
          this.showMore = false;
        }
      }
    }
  },

  destroyed() {
    this.overHeight = false;
    window.removeEventListener('scroll', this.isScrolledMobile);
  },
  created() {
    // offering-variants
    this.calculateOrderPrice();
  },
  methods: {
    isScrolledMobile() {
      let divOne = document.getElementById('offering-variants-mobile');
      let height =
        divOne.offsetHeight -
        window.outerHeight +
        (this.$vuetify.breakpoint.smAndDown ? 190 : 210);

      if (height > window.scrollY) {
        this.showMore = true;
      } else {
        this.showMore = false;
      }
    },
    isScrolled() {
      let divOne = document.getElementsByClassName('additional')[0];
      let divTwo = document.getElementsByClassName('offering-variants')[0];

      if (divOne.scrollTop < divTwo.offsetHeight - divOne.offsetHeight) {
        this.showMore = true;
      } else {
        this.showMore = false;
      }
    },
    scrollToBottom() {
      let divOne = null;
      let divTwo = null;
      if (window.matchMedia('(min-width: 800px)').matches) {
        divOne = this.$refs['offering-variants'].$el;
        divTwo = document.getElementsByClassName('additional')[0];
        divTwo.scrollTo({ top: divOne.scrollHeight, behavior: 'smooth' });
        this.showMore = false;
      } else {
        divOne = document.getElementById('offering-variants-mobile');
        window.scrollTo({ top: divOne.scrollHeight, behavior: 'smooth' });
      }
    },
    validateVariants() {
      const hasMetLimit = (offeringGroup, selection) =>
        selection.length <= offeringGroup.maxSelection &&
        selection.length >= offeringGroup.minSelection;
      // if no variants return true, otherwise validate selection
      return (
        !this.hasVariants ||
        this.offeringItemDetails.offeringGroups.every((offeringGroup) => {
          const isRequired = offeringGroup.type === 'required';
          const selection = this.variantsSelection[
            offeringGroup.offeringGroupId
          ];

          if (Boolean(selection)) {
            // if selected, validate selection min and max
            return hasMetLimit(offeringGroup, selection);
          } else if (isRequired) {
            // not selected but required
            return false;
          } else {
            // not selected and not required
            return true;
          }
        })
      );
    },
    addOrRemoveItemAnalytics(item) {
      let changeinQuantity = (a, b) => Math.abs(a - b);
      let changeinPrice = (a, b) => Math.abs(a - b);
      let updateItemAnalytics = {
        item_name: item.offering?.name,
        item_id: item.offering?.id,
        quantity: changeinQuantity(this.quantity, this.quantityBeforeEditing),
        type:
          this.quantity >= this.quantityBeforeEditing
            ? 'Update: increase quantity'
            : 'Update: reduce quantity',
      };
      return this.pushAddToCart([updateItemAnalytics]);
    },
    getVariantsAsPassesIds() {
      const passes = [];
      for (const groupOfferingId in this.variantsSelection) {
        const passIds = this.variantsSelection[groupOfferingId].map(
          (variant) => variant.id
        );
        passes.push(...passIds);
      }
      return passes;
    },
    getCurrentOrderInfo() {
      if (this.offeringItemDetails && this.offeringItemDetails.price) {
        return {
          offering: this.offeringItemDetails,
          quantity: this.quantity,
          selectedOfferingGroups: this.variantsSelection,
          passes: this.getVariantsAsPassesIds(),
          price: {
            value: this.priceValue,
            baseCurrency: this.offeringItemDetails.price.baseCurrency,
          },
        };
      }
      return null;
    },
    onVariantsChange(variantsSelection) {
      this.variantsSelection = variantsSelection;
      this.calculateOrderPrice();
    },
    onSubmit() {
      if (this.quantity > 0 && this.categories.status === 'OPEN') {
        if (this.validateVariants()) {
          this.showVariantsError = false;
          const orderItem = this.getCurrentOrderInfo();

          let similarItemInCart = this.cartItems.find(
            (item) => item.offering.id === orderItem.offering.id
          );
          if (this.isEditing) {
            this.updateItemInCart(this.orderId, orderItem);
            this.addOrRemoveItemAnalytics(orderItem);
          } else if (
            !!similarItemInCart &&
            !Boolean(orderItem.offering.offeringGroups?.length > 0)
          ) {
            orderItem.quantity = orderItem.quantity;
            orderItem.price.value = orderItem.price.value;
            this.updateItemInCart(orderItem);
            this.addOrRemoveItemAnalytics(orderItem);
          } else {
            this.addItemToCart(orderItem);
            let addItemAnalytics = {
              item_name: orderItem.offering?.name,
              item_id: orderItem.offering?.id,
              quantity: orderItem.quantity,
              price: orderItem.price.value,
            };
            this.pushAddToCart([addItemAnalytics]);
            this.pushHotJarAnalytics('add_to_cart');
          }
          this.$router
            .push({
              name: ROUTE_NAMES.SHOP_LANDING,
              params: { product_name: orderItem.offering?.name },
            })
            .catch((e) => {});
        } else {
          this.showVariantsError = true;
        }
      }
    },
    calculateOrderPrice() {
      if (this.offeringItemDetails?.price) {
        let basePrice = this.offeringItemDetails.price.value;
        if (this.hasVariants) {
          let commulativeVariantsPrice = 0;
          for (const groupOfferingId in this.variantsSelection) {
            commulativeVariantsPrice += this.variantsSelection[
              groupOfferingId
            ].reduce((prev, current) => prev + current.price.value, 0);
          }
          basePrice += commulativeVariantsPrice;
        }
        this.priceValue = this.quantity * basePrice;
      } else {
        this.priceValue = 0;
      }
    },
  },
  computed: {
    isEditing() {
      return Boolean(this.cartItem);
    },
    cartItem() {
      return this.cartItems?.find((item) => item.orderId === this.orderId);
    },
    actionButtonText() {
      return this.isEditing
        ? this.$t('shop.save_item')
        : this.$t('shop.add_to_order');
    },
    price() {
      return {
        value: this.priceValue,
        baseCurrency: this.offeringItemDetails?.price.baseCurrency,
      };
    },
    itemInCart() {
      let foundItems = [];
      if (this.hasCartItems) {
        foundItems = this.cartItems.filter(
          (x) => x.offering.id === this.offeringItemDetails.id
        );
      }
      if (foundItems && foundItems.length) {
        const totalNumberofOfferingsInCart = foundItems.reduce(
          (acc, o) => acc + o.quantity,
          0
        );
        return {
          status: true,
          qty: totalNumberofOfferingsInCart,
        };
      } else {
        return {
          status: false,
          qty: 0,
        };
      }
    },
    maxQuantity() {
      return this.offeringItemDetails && !this.isSoldOut
        ? this.offeringItemDetails.availableQuantity -
            (this.itemInCart.qty || 0)
        : 0;
    },
    filteredOfferingsInformation() {
      const bList = ['original_price', 'reduced_amount'];
      return this.offeringItemDetails.information?.filter(
        (info) => bList.indexOf(info.title) < 0
      );
    },
    containsInformation() {
      return (
        !!this.offeringItemDetails &&
        !!this.offeringItemDetails.information &&
        !!this.offeringItemDetails.information.length
      );
    },
    hasReducedAmount() {
      if (
        Boolean(this.originalPrice) &&
        Boolean(this.price) &&
        this.originalPrice != this.price.value
      ) {
        return true;
      } else {
        return false;
      }
    },
    reducedAmount() {
      return this.offeringItemDetails.information?.find(
        (info) => info.title === 'reduced_amount'
      );
    },
    originalPrice() {
      return this.offeringItemDetails.information?.find(
        (info) => info.title === 'original_price'
      );
    },
    hasOriginalPrice() {
      return Boolean(this.originalPrice);
    },
    hasVariants() {
      return Boolean(this.offeringItemDetails?.offeringGroups?.length > 0);
    },
    maxImgHeight() {
      return this.$vuetify.breakpoint.smAndDown ? 224 : 400;
    },
  },
  watch: {
    cartItem: {
      immediate: true,
      handler(cartItem) {
        if (cartItem) {
          this.quantity = cartItem.quantity;
          this.variantsSelection = cartItem.selectedOfferingGroups;
          this.calculateOrderPrice();
        }
      },
    },
    quantity: {
      immediate: true,
      handler(val) {
        this.calculateOrderPrice();
      },
    },
    isEditing(val, oldVal) {
      if (!oldVal && !!val) {
        this.quantityBeforeEditing = this.quantity;
      }
      if (!!oldVal && !val) {
        this.quantityBeforeEditing = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product {
  &-page {
    position: relative;
    padding-bottom: 20px;
  }
  &-image {
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 16px;

    @media (min-width: map-get($grid-breakpoints, md)) {
      margin-bottom: 28px;
    }
  }

  &-title {
    @include font-size(24, 150, 300);
    color: #0a0e14;
  }
}

.right-side {
  background: transparent;
  position: fixed;
  bottom: 0;
  z-index: 5;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));

  @media (min-width: map-get($grid-breakpoints, md)) {
    background: #fff;
    box-shadow: none;
    border: 1px solid #cfd5e0;
    border-radius: 8px;
    height: fit-content;
    overflow: hidden;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    max-height: 400px;
  }
}

.additional {
  position: relative;
  max-height: 400px;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1;
    width: 100%;
    background: #fff;
  }

  &-quantity {
    padding: 0 20px;

    @media (min-width: map-get($grid-breakpoints, md)) {
      padding: 0;
      position: relative;
    }

    &.has-shadow {
      box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.12);
    }
  }

  .offering-variants {
    position: relative;
    &.scroll {
      @-moz-document url-prefix() {
        padding-bottom: 130px !important;
      }
    }
  }
}

.price {
  @include font-size(20, 150, 600);
  color: var(--v-grey9-base);

  @media (min-width: map-get($grid-breakpoints, md)) {
    font-size: 18px;
  }
}

.card-actions {
  box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.12);
}

.original-price {
  @include font-size(14, 150, 500);
  color: var(--v-grey7-base);
  text-decoration: line-through;
}

.current-price {
  @include font-size(20, 150, 600);
  color: var(--v-grey8-base);
}

.save-price {
  @include font-size(14, 150, 500);
  color: #cd2026;
}

.navigation {
  &-icon {
    cursor: pointer;
    margin-right: 4px;
  }

  &-text {
    display: flex;
    @include font-size(14, 150);
    color: var(--v-primary-base);
    cursor: pointer;
    text-decoration: none;
  }

  &-product-name {
    @include font-size(14, 150);
    color: var(--v-grey7-base);
  }
}

.show-more {
  position: -webkit-sticky;
  position: sticky;
  bottom: 120px;
  width: 125px;
  margin: 0 auto 10px;
  @include font-size(12, 150, 500);
  color: var(--v-grey8-base);
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  align-items: center;
  align-content: center;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 15%);
  border-radius: 50px;
  justify-content: center;
  z-index: 1;
  background: #fff;
  cursor: pointer;
}
</style>
