<template>
  <div v-if="hasCategories && !!categoryList.length">
    <ShopCategoryList
      @active="onActive"
      @remove="onRemove"
      :category-info="category"
      :clickedFromMenu="clickedMenu"
      v-for="(category, i) in categoryList"
      :key="i"
      :order="i"
      :activeTab="activeTab"
      :ref="category.slug"
    />
  </div>
  <span class="listings-empty" v-else>{{
    $t('shop.items_unavailable_now')
  }}</span>
</template>

<script>
import ShopCategoryList from '@/modules/product/shop/features/ShopCategory/ShopCategoryList';
import ShopMixins from '@/modules/product/shop/mixins/ShopMixins';

export default {
  name: 'ShopCategories',
  components: { ShopCategoryList },
  mixins: [ShopMixins],
  props: {
    categoryList: {
      type: Array,
      default: () => [],
    },
    activeTab: Object,
    clickedMenu: Boolean,
  },

  methods: {
    onActive(block, order) {
      this.$emit('active', block, order);
    },

    onRemove(block, order) {
      this.$emit('remove', block, order);
    },
  },
};
</script>

<style lang="scss" scoped>
.listings-empty {
  min-height: 250px;
  @include font-size(16, 150);
  text-align: center;
  color: var(--v-grey8-base);
}
</style>
