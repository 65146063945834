<template>
  <v-dialog v-model="dialogModel" fullscreen scrollable>
    <v-card>
      <v-card-title class="pa-2">
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-0">
          <v-row :no-gutters="hasContextFlights">
            <v-col>
              <span class="checkout-title">{{ $t('shop.checkout') }}</span>
            </v-col>
          </v-row>
          <v-row dense v-if="hasContextFlights" class="cart-flights-info">
            <v-col
              ><span>{{
                $t('shop.for_flight', {
                  flightAirportCodes,
                  flightNumber: selectedContextFlight.flightNumber,
                })
              }}</span></v-col
            >
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="pt-6">
              <ShopCheckoutStatesStepper
                @goBackToForm="$emit('goBackToForm')"
                :state="checkoutState"
              />
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col>
              <span class="information-title">{{
                $t('shop.your_information')
              }}</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <ShopFormsContainer
                :is-loading="isLoading"
                :state="checkoutState"
              />
            </v-col>
          </v-row>
          <v-row v-if="isPaymentState">
            <v-col> <ShopCheckoutOrderSummary class="mt-3" /> </v-col
          ></v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="card-actions pa-0">
        <v-btn
          v-if="isStoreUnavailableStatus"
          to="/"
          tile
          depressed
          color="primary"
          block
          x-large
        >
          {{ $t('product.go_to_homepage') }}
        </v-btn>
        <v-btn
          v-else-if="!isPaymentState"
          tile
          depressed
          color="primary"
          block
          x-large
          :loading="isLoading"
          @click="createOrder"
          :disabled="!enableFormSubmitButton"
          >{{ $t('shop.proceed_payment') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import ShopFormsContainer from '@/modules/product/shop/features/ShopCheckout/ShopFormsContainer';
import ShopCheckoutOrderSummary from '@/modules/product/shop/features/ShopCheckout/ShopCheckoutOrderSummary';
import ShopCheckoutStatesStepper from '@/modules/product/shop/features/ShopCheckout/ShopCheckoutStatesStepper';
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';

export default {
  name: 'ShopCheckoutModal',
  components: {
    ShopCheckoutStatesStepper,
    ShopCheckoutOrderSummary,
    ShopFormsContainer,
  },
  mixins: [FlightsMixins, SelectedContextFlightMixins, ShopOrderMixins],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    enableFormSubmitButton: {
      type: Boolean,
      default: false,
    },
    checkoutState: {
      type: String,
      default: () => 'forms',
    },
  },

  data() {
    return {
      dialogModel: false,
    };
  },

  methods: {
    close() {
      this.dialogModel = false;
      this.$emit('backToMenu');
    },
  },

  computed: {
    isPaymentState() {
      return this.checkoutState === 'payment';
    },
  },

  watch: {
    open: {
      immediate: true,
      handler(open) {
        this.dialogModel = open;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.checkout-title {
  font-weight: 500;
  font-size: 24px;
  color: var(--v-grey9-base);
}

.information-title {
  font-weight: bold;
  font-size: 18px;
  color: var(--v-grey8-base);
}

.btn-return {
  text-decoration: underline;
  color: var(--v-error-base);
}
</style>
