<template>
  <v-container class="px-sm-0 pt-0">
    <v-row no-gutters class="top-sticky">
      <v-col cols="12" class="px-0" id="ShopSearch">
        <ShopSearchField
          @startSearch="startSearch"
          @closeSearch="closeSearch"
          @clearSearch="clearSearch"
        />
      </v-col>
    </v-row>
    <template v-if="startToSearch">
      <v-col cols="12">
        <v-skeleton-loader type="heading" class="mb-4"></v-skeleton-loader>
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-col>
    </template>
    <template v-else>
      <v-row no-gutters>
        <v-col cols="12" class="pb-md-3">
          <h1 class="search-result-title">
            <template v-if="searchText !== '' && searchText !== null">
              {{ $t('shop.search_results_for', { searchCount, searchText }) }}
            </template>
          </h1>
          <template v-if="searchCount == 0">
            <p class="search-result-subtitle">
              {{ $t('shop.try_modifying_search') }}
            </p>
          </template>
        </v-col>

        <template v-if="!startToSearch && searchCount > 0">
          <v-col cols="12">
            <ShopSearchResults :categoryInfo="searchResults" />
          </v-col>
        </template>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { ROUTE_NAMES } from '@/modules/product/shop/route';
import { RepoFactory } from '@/core/repositories';
import ShopSearchResults from '@/modules/product/shop/features/ShopSearchResults';
import ShopMixins from '@/modules/product/shop/mixins/ShopMixins';
import ShopSearchField from '@/modules/product/shop/ShopSearchField';

export default {
  name: 'CategoriesSearchPage',
  components: { ShopSearchResults, ShopSearchField },
  mixins: [ShopMixins],
  data() {
    return {
      searchIcon: require('@/assets/searchIcon.svg'),
      searchModel: null,
      searchResults: [],
      startToSearch: false,
      searchText: '',
      searchCount: '',
      response: null,
    };
  },
  computed: {
    searchTextFromStore() {
      return this.$store.getters[`${this.storeModuleName}/searchText`];
    },
    searchResultsFromStore() {
      return this.$store.getters[`${this.storeModuleName}/searchResults`];
    },
    isHasSearchResults() {
      return this.$store.getters[`${this.storeModuleName}/hasSearchResults`];
    },
  },
  methods: {
    clearSearchResults() {
      this.$store.dispatch(`${this.storeModuleName}/clearSearchResults`);
    },
    addSearchItemResults(item, text) {
      this.$store.dispatch(`${this.storeModuleName}/addSearchItemResults`, {
        item,
        text,
      });
    },
    async startSearch(val) {
      if (this.isHasSearchResults && val === null) {
        this.searchResults = this.searchResultsFromStore;
        this.searchCount = this.searchResults.length;
        this.searchText = this.searchTextFromStore;
      } else {
        this.clearSearchResults();
        this.startToSearch = true;

        const searchRepository = RepoFactory.get('search');
        this.isLoading = true;
        this.response = null;
        try {
          const { data } = await searchRepository.getGlobalSearchBOB(
            val,
            this.flight.id
          );
          this.response = data;

          this.searchResults = data.items;
        } catch (e) {
          this.error = e;
        } finally {
          this.isLoading = false;
        }

        this.searchText = val;
        this.searchCount = this.searchResults.length;
        this.addSearchItemResults(this.response.items, val);
        this.startToSearch = false;
        this.updateSearchQuery(val);
      }
    },
    updateSearchQuery(val) {
      this.$router
        .push({
          query: {
            q: val,
          },
        })
        .catch((err) => {});
    },
    closeSearch() {
      this.addSearchItemResults([], null);
      this.clearSearch();
      this.searchResults = null;
      this.$router.push({
        name: ROUTE_NAMES.SHOP_LANDING,
      });
    },
    deleteSearchQuery() {
      let query = Object.assign({}, this.$route.query);
      delete query['q'];
      this.$router.replace({ query });
    },
    clearSearch() {
      this.$router
        .push({
          query: {
            q: '',
          },
        })
        .catch((err) => {});
    },
  },
  watch: {
    ['$route.query.q']: {
      immediate: true,
      handler(val) {
        if (!!val) {
          this.searchModel = val;
        }
      },
    },
    searchResults: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!newVal) this.isAddFlightShowing = false;
      },
    },
  },
  created() {
    this.startSearch(this.searchModel);
    document.body.removeAttribute('class', 'noscroll');
  },
};
</script>

<style scoped lang="scss">
.top-sticky {
  position: sticky;
  top: 80px;
  z-index: 2;
  background: #fff;
  padding-top: 14px !important;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    top: 160px;
    padding-top: 26px !important;
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    top: 176px;
  }
}

.search-result {
  &-title {
    @include font-size(24, 150, 300);
    color: #0a0e14;
    margin-bottom: 16px;
    margin-top: 13px;

    @media (min-width: map-get($grid-breakpoints, md)) {
      margin-top: 20px;
      margin-bottom: 23px;
      font-size: 36px;
      color: #05090f;
    }
  }

  &-subtitle {
    @include font-size(16, 150);
    color: #0a0e14;

    @media (min-width: map-get($grid-breakpoints, md)) {
      font-size: 16px;
    }
  }
}
</style>
