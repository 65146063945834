<template>
  <div>
    <div v-if="isCatalogAvailable" class="shop-landing">
      <v-container
        fluid
        class="pt-0"
        :class="{ 'pa-0': $vuetify.breakpoint.smAndDown }"
      >
        <ShopOfferingDetails
          :offering-id="offeringDetailsId"
          :order-id="orderId"
        />
        <ShopCartDialog v-model="showCartDialog" />
        <v-row justify="center">
          <v-col cols="12" md="11" lg="10" class="pt-0">
            <v-container fluid class="pa-0">
              <v-row>
                <v-col cols="12" md="8" class="py-0">
                  <router-view> </router-view>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  v-if="$vuetify.breakpoint.mdAndUp"
                  class="py-0"
                >
                  <div
                    class="cart"
                    :class="{
                      'without-flight mt-n3': !hasSelectedContextFlight,
                    }"
                  >
                    <ShopCart />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      <v-container
        v-if="$vuetify.breakpoint.smAndDown && hasCartItems"
        class="checkout-container pa-0"
      >
        <v-row no-gutters>
          <v-col>
            <v-btn
              @click="onCheckout"
              tile
              depressed
              color="primary"
              block
              x-large
            >
              <v-container class="pa-0">
                <v-row justify="space-between">
                  <v-col cols="auto">
                    <span> {{ $t('shop.checkout') }} </span>
                  </v-col>
                  <v-col cols="auto">
                    <span>{{ totalCost | currency(defaultCurrency) }}</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else class="mt-16">
      <v-row justify="center">
        <v-col cols="12" md="5" class="d-flex flex-column align-center">
          <div class="shop-closed-image">
            {{ $t('shop.closed') }}
          </div>

          <p class="shop-closed-title">{{ $t('shop.catalog_unavailable') }}</p>
          <p class="shop-closed-subtitle">{{ $t('shop.shop_later') }}</p>
          <v-btn
            max-width="auto"
            class="ma-auto"
            color="primary"
            @click="goToConcierge()"
          >
            {{ $t('shop.return_home') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ShopMixins from '@/modules/product/shop/mixins/ShopMixins';
import OfferingListingMixins from '@/modules/product/shop/mixins/OfferingListingMixins';
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';
import ShopCart from '@/modules/product/shop/features/ShopCart/ShopCart';
import ShopOfferingDetails from '@/modules/product/shop/features/ShopOffering/ShopOfferingDetails';
import ShopCartDialog from '@/modules/product/shop/features/ShopCart/ShopCartDialog';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import HotJarMixins from '@/core/mixins/HotJarMixins';
import EnvUtils from '@/core/utils/environment';

export default {
  name: 'ShopLanding',
  components: {
    ShopCartDialog,
    ShopCart,
    ShopOfferingDetails,
  },
  mixins: [
    FlightsMixins,
    ShopMixins,
    OfferingListingMixins,
    ShopOrderMixins,
    GoogleTagManagerMixins,
    HotJarMixins,
  ],
  props: {
    selected: String,
  },
  data() {
    return {
      showCartDialog: false,
    };
  },
  computed: {
    offeringDetailsId() {
      return this.$route.query.offeringId;
    },
    orderId() {
      return this.$route.query.orderId;
    },
  },
  methods: {
    onCheckout() {
      this.pushToAnalytics();
      this.showCartDialog = true;
    },
    goToConcierge() {
      this.$router.push({
        name: 'concierge',
      });
    },
    pushToAnalytics() {
      let itemsList = [];
      for (let item of this.cartItems) {
        let product = {
          item_name: item.offering?.name,
          item_id: item.offering?.id,
          price: item.offering?.price.value,
          quantity: item.quantity,
        };
        itemsList.push(product);
      }
      this.pushBeginCheckout(itemsList);
      this.pushHotJarAnalytics('begin_checkout');
    },
  },
  watch: {
    ['$route']: {
      immediate: true,
      handler(route) {
        if (!!route && route.redirectedFrom) {
          let slug = route.redirectedFrom.split('/').pop();
          if (!!slug) {
            this.preselected = slug;
            this.selectCategory(slug);

            EnvUtils.redirectToWPNuxt(
              `/shop/${route.params.id}/catalog/${slug}`
            );
          }
        }
      },
    },
    categoryWithAssociatedOfferings(val) {
      if (val && !!val.length) {
        this.pushProductImpressions(val);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.shop-landing {
  .checkout-container {
    position: fixed;
    bottom: 0;
    z-index: 4;
    box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.12);
  }
  .cart {
    position: sticky;
    top: 85px;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      top: 164px;
    }

    @media (min-width: map-get($grid-breakpoints, md)) {
      top: 176px;
    }

    &-without-flight {
      position: sticky;
      top: -12px;

      @media (min-width: map-get($grid-breakpoints, md)) {
        top: 168px;
      }
    }
  }
}
.shop-closed {
  &-image {
    width: 189px;
    height: 171.41px;
    background: url('/svg/shop_closed.svg');
    display: flex;
    justify-content: center;
    align-items: center;
    @include font-size(26.77, 150, 500);
    color: var(--v-grey5-base);
    padding-top: 30px;
    margin-bottom: 66px;
  }

  &-title,
  &-subtitle {
    text-align: center;
  }

  &-title {
    @include font-size(30, 150, 500);
    margin-bottom: 11px;
  }

  &-subtitle {
    @include font-size(18, 150);
    margin-bottom: 23px;
  }
}
</style>
