<template>
  <v-container class="pa-0">
    <ShopCheckoutForm v-if="state === 'forms'"></ShopCheckoutForm>
    <ShopCheckoutPayment :isLoading="isLoading" v-else></ShopCheckoutPayment>
  </v-container>
</template>

<script>
import ShopCheckoutPayment from '@/modules/product/shop/features/ShopCheckout/ShopCheckoutPayment';
import ShopCheckoutForm from '@/modules/product/shop/features/ShopCheckout/ShopCheckoutForm';

export default {
  name: 'ShopFormsContainer',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    state: {
      type: String,
      default: () => 'forms',
      validator: function(value) {
        return ['forms', 'payment'].includes(value);
      },
    },
  },
  watch: {
    state(val) {
      if (!!val) {
        window.scrollTo(0, 0);
      }
    },
  },
  components: {
    ShopCheckoutForm,
    ShopCheckoutPayment,
  },
};
</script>

<style scoped></style>
