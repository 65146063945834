var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"py-0",staticStyle:{"height":"100%"}},[_c('v-row',[(_vm.isLoadingListingsForTheFirstTime && !_vm.isCategoriesEmpty)?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{staticClass:"mb-4",attrs:{"type":"heading"}}),_c('v-skeleton-loader',{staticClass:"mb-4",attrs:{"type":"heading"}}),_c('v-skeleton-loader',{attrs:{"type":"card"}}),_c('v-skeleton-loader',{attrs:{"type":"card"}}),_c('v-skeleton-loader',{attrs:{"type":"card"}})],1)]:[_c('v-col',{staticClass:"pa-0 sticky-menu-bar",class:{
          'without-flight': !_vm.hasSelectedContextFlight,
        },on:{"scroll":_vm.handleScroll}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-space-between align-center",class:[
              _vm.isSearchActive ? 'pb-0' : 'pb-2',
              {
                'px-3': _vm.$vuetify.breakpoint.smAndDown,
              },
            ],attrs:{"id":"ShopSearch","cols":"12"}},[(_vm.isSearchActive)?[_c('ShopSearchField',{attrs:{"isCategoryListPage":true},on:{"startSearch":_vm.startSearch,"closeSearch":_vm.closeSearch,"clearSearch":_vm.clearSearch}})]:[_c('div',{staticClass:"d-inline"},[_c('span',{staticClass:"shop-title",attrs:{"data-cy":"shop-store-title"}},[_vm._v(_vm._s(_vm.shopTitle))]),(!!_vm.categoryWithAssociatedOfferings.length)?_c('span',{staticClass:"mx-3",class:{
                    'success--text': _vm.isOpen,
                    'error--text': !_vm.isOpen,
                  },attrs:{"data-cy":"shop-store-status"}},[_vm._v(" "+_vm._s(_vm.shopStatusText)+" ")]):_vm._e()]),(_vm.searchFF && _vm.isOpen)?[_c('v-btn',{attrs:{"type":"button","text":"","small":"","icon":"","width":"20","height":"20","aria-label":"open search","title":"open search"},on:{"click":_vm.toggleSearch}},[_c('v-img',{attrs:{"aria-hidden":"true","contain":"","src":_vm.searchIcon,"alt":"search icon"}})],1)]:_vm._e()]],2),(!!_vm.categoryWithAssociatedOfferings.length)?_c('v-col',{staticClass:"pa-0 top-block",attrs:{"cols":"12"}},[(!!_vm.allCategories && _vm.allCategories.length > 1)?_c('CategoryMenuBar',{attrs:{"menuItems":_vm.allFormattedCategories,"hasSelectedContextFlight":_vm.hasSelectedContextFlight,"activeTabId":_vm.activeTabId},on:{"click":_vm.onMenuItemClick}}):_vm._e(),(_vm.isSearchActive)?[_c('div',{staticClass:"gl-search-container"})]:_vm._e()],2):_vm._e()],1)],1),_c('v-col',{staticClass:"shop-list-wrapper",attrs:{"cols":"12"}},[_c('ShopCategories',{attrs:{"clickedMenu":_vm.isClickedFromMenu,"activeTab":_vm.activeTabId,"category-list":_vm.categoryWithAssociatedOfferings},on:{"scroll":_vm.handleScroll,"active":_vm.addToActive,"remove":_vm.removeFromActive}})],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }