<template>
  <div>
    <CategoriesListPage @openSearch="openSearch" />
    <template v-if="searchIsOpen">
      <div class="gl-search-container"></div>
    </template>
  </div>
</template>

<script>
import CategoriesListPage from '@/modules/product/shop/CategoriesListPage';

export default {
  name: 'ShopCategoriesListPage',
  components: { CategoriesListPage },
  data() {
    return {
      searchIsOpen: false,
    };
  },
  methods: {
    openSearch(val) {
      this.searchIsOpen = val;
    },
  },
};
</script>

<style scoped lang="scss">
.gl-search-container {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  z-index: 3;
  overflow: hidden;
}
</style>
