<template>
  <v-container fluid class="pa-0">
    <AddFlightModal v-if="addFlightMode" :isOpen="addFlightMode" />
    <v-snackbar
      class="alert-information"
      color="var(--v-grey1-base)"
      :timeout="timeout"
      top
      height="80"
      width="375"
      v-model="snackbar"
    >
      <span
        data-cy="product-action-alert-message"
        class="snack-text black--text"
      >
        {{ alertText }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          small
          text
          v-bind="attrs"
          @click="closeSnackBar"
          aria-label="button for Alert"
          title="button for Alert"
        >
          <v-icon color="#858F9D"> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-row v-if="isLoadingStore" justify="center" align="center">
      <v-col cols="auto">
        <v-progress-circular color="primary" indeterminate />
      </v-col>
    </v-row>
    <v-row v-else no-gutters>
      <v-col cols="12">
        <ShopLanding
          @refresh="refreshOfferingsList"
          :selected="selectedCategory"
          :shopCategories="categoryWithAssociatedOfferings"
          :cartInfo="cartItems"
          :total="totalCost"
          :hasCartItems="hasCartItems"
          :quantity="totalQuantity"
          :currency="defaultCurrency"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShopMixins from '@/modules/product/shop/mixins/ShopMixins.vue';
import ShopOrderMixin from '@/modules/product/shop/mixins/ShopOrderMixins.vue';
import OfferingListingMixins from '@/modules/product/shop/mixins/OfferingListingMixins.vue';
import RealtimeMixins from '@/modules/product/shop/mixins/RealtimeMixins.vue';
import ShopLanding from '@/modules/product/shop/features/ShopLanding';
import AddFlightModal from '@/modules/common/components/AddFlightModal.vue';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import { ROUTE_NAMES } from '@/modules/product/shop/route';
import EnvUtils from '@/core/utils/environment';

export default {
  name: 'ShopLandingPage',
  components: { ShopLanding, AddFlightModal },
  mixins: [
    ShopMixins,
    OfferingListingMixins,
    ShopOrderMixin,
    RealtimeMixins,
    SelectedContextFlightMixins,
  ],
  methods: {
    refreshOfferingsList() {
      if (
        !!this.categoryOfferingTypeIds &&
        this.categoryOfferingTypeIds.length
      ) {
        this.loadListings(this.categoryOfferingTypeIds);
      }
    },
    clearCartInfo() {
      this.resetOrderCreation();
      this.resetOrderCheckout();
      this.clearSpecialInstructions();
    },
    openSearch(val) {
      this.searchIsOpen = val;
    },
    goToErrorPage() {
      const notFoundError =
        this.categoriesError && this.categoriesError.status === 400;
      const internalServerError =
        this.categoriesError && this.categoriesError.status === 500;

      if (notFoundError) this.$router.push('/404');
      if (internalServerError) this.$router.push('/500');
    },
  },
  data() {
    return {
      addFlightMode: false,
      searchIsOpen: false,
      timeout: 7000,
    };
  },
  computed: {
    categoryWithAssociatedOfferingsTrigger() {
      return JSON.stringify(
        this.categoryWithAssociatedOfferings.map((category) => ({
          name: category.name,
          slug: category.slug,
          id: category.id,
          offeringTypeIds: category.offeringTypeIds.join(','),
          offerings: category.offerings.map((o) => o.id).join(','),
        }))
      );
    },
    alertText() {
      if (this.hasFlightMisMatch) {
        return this.$t('shop.alert_message_flight_mismatch');
      }
      if (!!this.$route.params && !!this.$route.params.product_name) {
        return this.$t('shop.alert_message', {
          product_name: this.$route.params.product_name,
        });
      }
      return '';
    },
  },
  watch: {
    selectedContextFlight(selectedContextFlight) {
      if (selectedContextFlight)
        this.$router.replace({ name: ROUTE_NAMES.SHOP });
    },

    categoryOfferingTypeIds: {
      immediate: false,
      handler(val) {
        if (!!val && val.length && this.hasListingsValue === false) {
          this.loadListings(this.categoryOfferingTypeIds);
        }
      },
    },
    hasCategories(val, oldVal) {
      if (!val) {
        this.loadCategories();
      }
    },
    hasCategoriesError(val) {
      if (!!val) {
        this.goToErrorPage();
      }
    },
    categoryWithAssociatedOfferingsTrigger(val) {
      if (!this.categoryWithAssociatedOfferings.length) {
        this.clearCart();
      }
    },
    $route: {
      immediate: true,
      handler(val, oldVal) {
        if (val && val.name == 'shop-landing') {
          this.refreshOfferingsList();
        }
        if (!!val && val.query.addFlightModal) {
          this.addFlightMode = true;
        }
        if (!!oldVal && oldVal.query.addFlightModal) {
          this.addFlightMode = false;
        }
        if (!!val && !!this.hasCategoriesError) {
          this.goToErrorPage();
        }
        if (!!val && !!val.params.id) {
          this.loadCategories();
        }

        if ((!!val && val.params.product_name) || !!this.hasFlightMisMatch) {
          this.snackbar = true;
        } else {
          this.snackbar = false;
        }
      },
    },
    categories: {
      immediate: true,
      async handler(newCategories) {
        if (!!newCategories && newCategories.storeDetails) {
          this.subscribeRealtimeActionsToNewFlight(
            newCategories.storeDetails.storeChannel
          );
        }
      },
    },
  },
  created() {
    this.clearCartInfo();
    this.updateStoreStatus(this.shopStoreId);
  },

  mounted() {
    if (!this.hasCategories) {
      this.loadCategories();
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep.alert-information {
  .snack-text {
    text-align: center;
    @include font-size(16, 150);
  }
}

::v-deep .v-snack__wrapper {
  top: 155px !important;
}
</style>
