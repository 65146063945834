<template>
  <v-container class="pa-0">
    <div v-if="!productDetails.value && productDetails.isLoading">
      <v-skeleton-loader type="heading" width="25%" />
      <v-row class="mt-2">
        <v-col cols="12" md="7">
          <v-skeleton-loader type="image" />
        </v-col>
        <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="1" />
        <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="0" md="4">
          <v-skeleton-loader type="card" height="110px" />
        </v-col>
      </v-row>
      <v-skeleton-loader type="heading" width="25%" class="mt-4" />
      <v-skeleton-loader type="heading" width="15%" class="mt-3" />
    </div>
    <v-alert v-else-if="productDetails.error" dense outlined type="error">
      {{ productDetails.error }}
    </v-alert>
    <ShopProductPage v-else :offeringItemDetails="productDetails.value" />
  </v-container>
</template>

<script>
import OfferingListingMixins from '@/modules/product/shop/mixins/OfferingListingMixins';
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';
import ShopProductPage from '@/modules/product/shop/features/ShopProductPage';
import { RepoFactory } from '@/core/repositories';
export default {
  name: 'ShopProductContainer',
  components: { ShopProductPage },
  mixins: [OfferingListingMixins, ShopOrderMixins],
  data() {
    return {
      productId: this.$route.params.offeringId,
      productDetails: {
        value: null,
        isLoading: false,
        error: null,
      },
    };
  },
  methods: {
    loadOfferingWhenUnavailable() {
      if (!this.hasDetails) {
        this.loadOffering(this.productId);
      } else {
        this.productDetails.value = this.offeringItemDetails;
      }
    },
    async loadOffering(offeringId, storeId = this.shopStoreId) {
      this.productDetails.isLoading = true;
      const storeRepo = RepoFactory.get('shop', { resourceId: storeId });
      try {
        this.productDetails.value = await storeRepo.getOffering(offeringId);
      } catch (error) {
        if (error.response && error.response.status < 500) {
          this.redirectToNotFound();
        } else {
          this.redirectToServerError();
        }
      } finally {
        this.productDetails.isLoading = false;
      }
    },
    redirectToNotFound() {
      return this.$router.push('/404');
    },
    redirectToServerError() {
      return this.$router.push('/500');
    },
  },

  computed: {
    hasDetails() {
      return Boolean(this.offeringItemDetails);
    },
    offeringItemDetails() {
      if (!this.productId) return null;
      return this.allCategoryOfferings.find((x) => x.id === this.productId);
    },
  },

  mounted() {
    if (!this.hasCategories) {
      this.loadCategories();
    }
  },
  watch: {
    productId: {
      immediate: true,
      handler(val) {
        if (!!val) {
          this.loadOfferingWhenUnavailable();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
