<template>
  <v-container
    fluid
    class="pa-0"
    :style="hasSelectedContextFlight ? '' : 'top:-2px'"
    data-cy="category-menu-bar"
  >
    <v-row justify="center" dense>
      <v-col ref="scrollWrapper" class="py-3 wrapper-scrollable">
        <p
          ref="leftChevron"
          @click="scrollLeft"
          class="chevron-overlay left ma-0"
        >
          <v-icon size="26px"> mdi-chevron-left </v-icon>
        </p>
        <p
          ref="rightChevron"
          @click="scrollRight"
          class="chevron-overlay right ma-0"
        >
          <v-icon size="26px"> mdi-chevron-right </v-icon>
        </p>

        <div
          @scroll="updateScrollButtonsVisibility"
          ref="scrollContainer"
          class="scrollable hide-scrollbar"
        >
          <span
            :ref="item.id"
            :id="item.id"
            @click="onClick(item.id)"
            :class="['category-menu-item d-inline-flex']"
            v-for="(item, key) in menuItems"
            :key="key"
          >
            {{ item.title }}
          </span>
        </div>
      </v-col>
    </v-row>
    <v-divider />
  </v-container>
</template>

<script>
export default {
  name: 'CategoryMenuBar',
  props: {
    menuItems: Array,
    hasSelectedContextFlight: Boolean,
  },

  data() {
    return {
      itemClicked: null,
    };
  },

  mounted() {
    this.updateScrollButtonsVisibility();
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onWindowWidthChanged);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onWindowWidthChanged);
  },

  watch: {
    ['$route']: {
      immediate: true,
      handler(val) {
        if (!!val && val.redirectedFrom) {
          let slug = val.redirectedFrom.split('/').pop();
          let activeMenuItem = this.menuItems.find((item) => item.id == slug);
          if (slug && activeMenuItem) {
            this.onClick(activeMenuItem.id);
          }
        }
      },
    },
  },

  methods: {
    onWindowWidthChanged() {
      this.updateScrollButtonsVisibility();
    },

    onClick(id) {
      this.itemClicked = id;
      this.$emit('click', id);
    },

    scrollRight() {
      let scrollRemainedToTheEnd =
        this.$refs.scrollContainer.scrollWidth -
        (this.$refs.scrollContainer.scrollLeft +
          this.$refs.scrollContainer.clientWidth);
      if (scrollRemainedToTheEnd >= 200 * 2) {
        this.$refs.scrollContainer.scrollLeft += 200;
      } else {
        this.$refs.scrollContainer.scrollLeft += scrollRemainedToTheEnd;
      }
    },

    scrollLeft() {
      let scrollRemainedToTheStart = this.$refs.scrollContainer.scrollLeft;
      if (scrollRemainedToTheStart >= 200 * 2) {
        this.$refs.scrollContainer.scrollLeft -= 200;
      } else {
        this.$refs.scrollContainer.scrollLeft -= scrollRemainedToTheStart;
      }
    },

    updateScrollButtonsVisibility(e) {
      if (this.$refs.scrollContainer.scrollLeft < 10) {
        this.$refs.leftChevron.style.visibility = 'hidden';
      } else {
        this.$refs.leftChevron.style.visibility = 'visible';
      }

      if (
        this.$refs.scrollContainer.scrollLeft >
        this.$refs.scrollContainer.scrollWidth -
          this.$refs.scrollContainer.clientWidth -
          10
      ) {
        this.$refs.rightChevron.style.visibility = 'hidden';
      } else {
        this.$refs.rightChevron.style.visibility = 'visible';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.category {
  &-menu-wrapper {
    position: relative;

    &:after {
      content: '';
      height: 100%;
      width: 48px;
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    }
  }

  &-menu-item {
    cursor: pointer;
    @include font-size(16, 150, 400);
    color: var(--v-primary-base);
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-right: 18px;

    @media (min-width: map-get($grid-breakpoints, md)) {
      margin-right: 24px;
    }
  }
}

.wrapper-scrollable {
  position: relative;
}

.shop {
  &-open {
    background: #1890ff;
  }

  &-closed {
    background: #ff4d4f;
  }

  &-paused {
    background: #faad14;
  }

  &-preordered {
    background: #1890ff;
  }
}

.scrollable {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.chevron-overlay {
  position: absolute;
  z-index: 2;
  width: 60px;
  color: var(--v-grey7-base);
  display: flex;
  cursor: pointer;
}

.left {
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    #fff 50%,
    #fff 100%
  );
  left: 0;
  visibility: hidden;
  justify-content: flex-start;
}

.right {
  background: linear-gradient(
    270deg,
    #fff 0%,
    #fff 50%,
    rgba(255, 255, 255, 0) 100%
  );
  right: 0;
  justify-content: flex-end;
}
</style>
