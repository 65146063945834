var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
    handler: _vm.onIntersect,
    options: {
      threshold: _vm.intersectThreshold,
      rootMargin: _vm.intersectRootMargin,
    },
  }),expression:"{\n    handler: onIntersect,\n    options: {\n      threshold: intersectThreshold,\n      rootMargin: intersectRootMargin,\n    },\n  }"}],staticClass:"mx-auto",attrs:{"max-width":"336"}},[_c('v-container',{ref:_vm.categoryInfo.slug,staticClass:"shop-category-list px-0",class:{ 'pa-0': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"fluid":"","data-cy":"category-component"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"mobile-divider"}):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"pb-0",class:{ 'px-0': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"cols":"12"}},[_c('span',{staticClass:"d-block category-name"},[_vm._v(_vm._s(_vm.categoryInfo.name))]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-divider',{staticClass:"mt-3"}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('v-container',{staticClass:"pa-0"},[_vm._l((_vm.categoryInfo.offerings),function(item,index){return [_c('ShopCategoryListItem',{key:item.id,attrs:{"item":item},on:{"click":_vm.onItemClick}}),(
                _vm.$vuetify.breakpoint.smAndDown &&
                  index !== _vm.categoryInfo.offerings.length - 1
              )?_c('v-divider',{key:`divider-${item.id}`}):_vm._e()]})],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }