<template>
  <v-container class="pa-0">
    <CheckoutForm
      @formStatus="updateCheckoutForm"
      @clear="clearErrors"
      no-padding
      :customerData="customerInfo"
      :instructions="specialInstructions"
      :optional-seat-number="optionalSeatFF"
      :is-loading="isLoading"
      @submit:forms="onSubmitCustomerForm"
      :show-form-submit-btn="$vuetify.breakpoint.mdAndUp"
      :hasAnyRestrictedItems="hasCartAnyRestrictedItems"
      :disable-submition="disableSubmition"
    />
  </v-container>
</template>

<script>
import CheckoutForm from '@/modules/product/shop/features/Checkout/CheckoutForm';
import ShopMixins from '@/modules/product/shop/mixins/ShopMixins';
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';
import ConfigsMixins from '@/core/mixins/ConfigsMixins';

export default {
  name: 'ShopCheckoutForm',
  mixins: [ShopMixins, ShopOrderMixins, ConfigsMixins],
  components: {
    CheckoutForm,
  },
  created() {
    this.resetRestrictionAcknowledged();
    this.checkOptionalSeatFF();
    this.resetOrderCreation();
    this.resetInfoFormValidations();
  },
  data() {
    return {
      formValid: false,
      customerDetails: null,
      optionalSeatFF: false,
      isLoading: false,
    };
  },
  computed: {
    disableSubmition() {
      return (
        this.hasOrderCreationError &&
        this.orderCreationStatus === 'storeUnavailable'
      );
    },
  },
  methods: {
    async onSubmitCustomerForm() {
      this.isLoading = true;
      await this.createOrder();
      this.isLoading = false;
    },
    async checkOptionalSeatFF() {
      this.optionalSeatFF = await this.$ldclient.variation(
        'shop-optional-seat-number',
        false,
        true
      );
      this.$ldclient.$on('change:shop-optional-seat-number', (value) => {
        this.optionalSeatFF = value;
      });
    },
    updateCheckoutForm(info) {
      this.formValid = info.value;
      this.updateInfoFormValid(info.value);
      const {
        title,
        firstName,
        lastName,
        email,
        phoneNumber,
        seatRow,
        seatColumn,
        noSeat,
        specialInstructions,
        restrictionAcknowledged,
        pnr,
      } = info.customer;
      this.customerDetails = {
        title,
        firstName,
        lastName,
        email,
        phoneNumber,
        seatRow,
        seatColumn,
        noSeat,
        specialInstructions,
        restrictionAcknowledged,
        pnr,
      };
      this.updateCustomerInfo(this.customerDetails);
    },
    clearErrors() {
      this.clearOrderCreationError();
      this.clearOrderCheckoutError();
      this.clearStripeError();
    },
  },
};
</script>

<style scoped></style>
