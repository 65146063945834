<template>
  <v-text-field
    class="search-field"
    autofocus
    height="44"
    outlined
    hide-details
    single-line
    full-width
    required
    dense
    clearable
    clear-icon="mdi-close"
    :placeholder="$t('shop.search.search_field_placeholder')"
    v-model="queryModel"
    @keydown.enter.prevent="startSearch(queryModel)"
    @click:clear="clearSearch"
    @keydown.esc="isCategoryListPage ? closeSearch() : ''"
    @blur="isCategoryListPage ? closeSearch() : ''"
    label=""
    aria-labelledby="ShopSearch"
    aria-selected="true"
    tabindex="0"
  >
    <template v-slot:prepend-inner>
      <v-btn
        icon
        small
        height="24"
        width="24"
        type="button"
        aria-label="close search"
        title="close search"
        @click="closeSearch"
      >
        <v-icon aria-hidden="true" size="24px">
          mdi-arrow-left
        </v-icon>
      </v-btn>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'ShopSearchField',
  data() {
    return {
      queryModel: null,
    };
  },
  props: {
    isCategoryListPage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    startSearch(val) {
      this.$emit('startSearch', val);
    },
    closeSearch() {
      this.$emit('closeSearch');
    },
    clearSearch() {
      this.$emit('clearSearch');
    },
  },
  watch: {
    ['$route.query.q']: {
      immediate: true,
      handler(val) {
        if (!!val) {
          this.queryModel = val;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  z-index: 10;
}

.search-field {
  width: 100%;
  border-radius: 8px;
  background: #fff;
  z-index: 4;
  position: relative;

  ::v-deep fieldset {
    border-width: 1px;
  }

  &.v-input--is-focused {
    ::v-deep fieldset {
      transition-duration: 0s !important;
      border: 2px solid var(--v-primary-base) !important;
    }
  }
  &.v-input--is-focused {
    ::v-deep .mdi-arrow-left::before {
      color: var(--v-primary-base) !important;
    }
  }

  ::v-deep .v-text-field__slot {
    padding-left: 30px;
  }
}

::v-deep .v-input__prepend-inner,
::v-deep .v-input__append-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto !important;
  height: 24px;
  width: 24px;
  z-index: 2;
}

::v-deep .v-input__append-inner {
  right: 15px;
}

::v-deep .v-input__prepend-inner {
  left: 10px;
}
</style>
