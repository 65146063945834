<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="11" lg="10" justify="center">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';
import { ROUTE_NAMES } from '@/modules/product/shop/route';

export default {
  name: 'ShopCheckoutPage',
  mixins: [ShopOrderMixins],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.hasCartItems) {
        next(true);
      } else {
        next({
          name: ROUTE_NAMES.SHOP_LANDING,
        });
      }
    });
  },
};
</script>

<style scoped></style>
