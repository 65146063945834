<template>
  <v-row no-gutters class="py-0" :class="{ 'px-4': !noPadding }">
    <v-form ref="checkoutForm" class="checkout-form" role="form">
      <v-col class="pa-0">
        <p class="mb-1 single-text field-title mt-2">
          {{ $t('shop.email') }}
        </p>
      </v-col>
      <v-col class="pa-0" id="emailFieldV3">
        <v-text-field
          data-cy="email-checkout-field-input"
          name="email"
          class="field-input single-line-error"
          dense
          outlined
          placeholder="email@mail.com"
          single-line
          @blur="onDebouncedFieldChange(customer.email, 'email')"
          :rules="emailRules"
          v-model="customer.email"
          label=""
          aria-labelledby="emailFieldV3"
          aria-selected="true"
          tabindex="0"
        />
      </v-col>
      <v-col class="pa-0 single-text mb-6 mt-0">
        {{ $t('shop.where_receipts_is_sent') }}
      </v-col>
      <v-col class="pa-0">
        <p class="mb-1 single-text field-title mt-2">
          {{ $t('shop.full_name') }}
        </p>
      </v-col>
      <v-col class="pa-0" id="fullNameFieldV3">
        <v-text-field
          data-cy="fullName-checkout-field-input"
          name="fullName"
          class="field-input single-line-error"
          dense
          outlined
          placeholder="Your name"
          single-line
          @blur="onDebouncedFieldChange(customer.fullName, 'fullName')"
          v-model="customer.fullName"
          :rules="fullNameRules"
          label=""
          aria-labelledby="fullNameFieldV3"
          aria-selected="true"
          tabindex="0"
        />
      </v-col>
      <v-col class="pa-0">
        <p class="mb-1 single-text field-title">
          {{ $t('shop.seat_number_label') }}
          <template v-if="optionalSeatNumber">{{
            $t('shop.optional')
          }}</template>
        </p>
      </v-col>
      <v-col class="pa-0" id="seatFieldV3">
        <v-text-field
          data-cy="seat-checkout-field-input"
          name="seat"
          class="field-input single-line-error"
          dense
          placeholder="e.g. 16A"
          outlined
          single-line
          v-model="customer.seat"
          @blur="onDebouncedFieldChange(customer.seat, 'seat')"
          :rules="seatRules"
          label=""
          aria-labelledby="seatFieldV3"
          aria-selected="true"
          tabindex="0"
        />
      </v-col>
      <v-col class="pa-0 single-text mb-6 mt-0">
        {{ seatHelpText }}
      </v-col>

      <div v-if="enablePnr">
        <v-col class="pa-0">
          <p class="mb-1 single-text field-title mt-2">
            {{ pnrTitle }} {{ $t('shop.optional') }}
          </p>
        </v-col>
        <v-col class="pa-0" id="pnrTitle">
          <v-text-field
            data-cy="pnr-checkout-field-input"
            name="pnr"
            class="field-input single-line-error"
            dense
            outlined
            placeholder="e.g. LZTZ2E"
            single-line
            @keyup="customer.pnr = customer.pnr.toUpperCase()"
            @blur="onDebouncedFieldChange(customer.pnr, 'pnr')"
            v-model="customer.pnr"
            :rules="pnrRules"
            label=""
            aria-labelledby="pnrTitle"
            aria-selected="true"
            tabindex="0"
          />
        </v-col>
        <v-col class="pa-0 single-text mb-6 mt-0">
          {{ pnrNote }}
        </v-col>
      </div>

      <div v-if="hasAnyRestrictedItems">
        <p class="font-weight-bold mb-1">
          {{ restrictionTitle }}
        </p>
        <div class="d-flex">
          <v-checkbox
            v-model="restrictionAcknowledged"
            class="mt-0"
          ></v-checkbox>
          <span
            class="pa-0 mb-6 mt-2 single-text d-flex"
            @click="restrictionAcknowledged = !restrictionAcknowledged"
          >
            {{ restrictionText }}
          </span>
        </div>
      </div>

      <v-row justify="end" v-if="showFormSubmitBtn" no-gutters>
        <v-btn
          data-cy="form-submit-button"
          :disabled="enableSubmitButton || hasOrderCreationError"
          :loading="isLoading"
          depressed
          large
          color="primary"
          @click="$emit('submit:forms')"
          tabindex="0"
        >
          {{ $t('shop.proceed_payment') }}
        </v-btn>
      </v-row>
    </v-form>
  </v-row>
</template>

<script>
import _isEqual from 'lodash/isEqual';
import _debounce from 'lodash/debounce';
import ConfigsMixins from '@/core/mixins/ConfigsMixins';

export default {
  name: 'CheckoutForm',
  mixins: [ConfigsMixins],
  data() {
    let isFormValid =
      !!this.customerData.email &&
      !!this.customerData.firstName &&
      !!!!this.customerData.lastName;
    if (!this.optionalSeatNumber) {
      isFormValid =
        isFormValid &&
        !!this.customerData.seatRow &&
        !!this.customerData.seatColumn;
    }
    if (this.hasAnyRestrictedItems) {
      isFormValid = isFormValid && this.customerData.restrictionAcknowledged;
    }
    const isFullNameFilled =
      !!this.customerData.firstName && !!this.customerData.lastName;
    return {
      restrictionAcknowledged: this.customerData.restrictionAcknowledged,
      isFormValid,
      currentValue: '',
      fieldsFilled: {
        email: !!this.customerData.email,
        fullName: isFullNameFilled,
        seat: !!this.customerData.seatRow || !!this.customerData.seatColumn,
      },
      customer: {
        fullName: isFullNameFilled
          ? `${this.customerData.firstName} ${this.customerData.lastName}`
          : '',
        email: `${this.customerData.email}` || '',
        seat:
          `${this.customerData.seatRow}${this.customerData.seatColumn}` || '',
        pnr: this.customerData.pnr,
      },
      visitedField: {
        seat: false,
        fullName: false,
        email: false,
      },
      enablePnrFF: false,
    };
  },
  props: {
    customerData: Object,
    instructions: String,
    isLoading: Boolean,
    noPadding: {
      type: Boolean,
      default: false,
    },
    optionalSeatNumber: {
      type: Boolean,
      default: false,
    },
    hasAnyRestrictedItems: {
      type: Boolean,
      default: false,
    },
    showFormSubmitBtn: {
      type: Boolean,
      default: false,
    },
    disableSubmition: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async checkPnrFF() {
      this.enablePnrFF = await this.$ldclient.variation(
        'enable-pnr',
        false,
        true
      );
      this.$ldclient.$on('change:enable-pnr', (value) => {
        this.enablePnrFF = value;
      });
    },
    validateForPefilledValues() {
      let areAllFieldsFilled = this.validateCustomerFields(this.customer);
      if (!!this.$refs && !!this.$refs.checkoutForm) {
        this.isFormValid =
          this.$refs.checkoutForm.validate() && areAllFieldsFilled;
      }
    },
    validateCustomerFields(customer) {
      let isFormValid = false;
      if (customer) {
        isFormValid = !!customer.email && !!customer.fullName;
        if (!this.optionalSeatNumber) {
          isFormValid = isFormValid && !!customer.seat;
        }
        if (this.hasAnyRestrictedItems) {
          isFormValid = isFormValid && this.restrictionAcknowledged;
        }
      }
      return isFormValid;
    },
    onDebouncedFieldChange(val, name) {
      this.debounce(this.onFieldChange(val, name), 1000);
    },
    debounce(cb, delay) {
      let timeout;
      return function() {
        clearTimeout(timeout);
        timeout = setTimeout(callback, delay);
      };
    },
    onFieldChange(value, name) {
      this.$emit('clear');
      this.fieldsFilled[name] = !!value ? true : false;
      this.visitedField[name] = true;
      this.currentValue = value;
      if (!!this.$refs && !!this.$refs.checkoutForm) {
        let areAllFieldsFilled = this.validateCustomerFields(this.customer);

        this.isFormValid =
          this.$refs.checkoutForm.validate() && areAllFieldsFilled;
        this.$emit('formStatus', {
          value: this.isFormValid,
          customer: this.customerInfo,
        });
      }
    },
  },
  created() {
    this.checkPnrFF();
    this.$emit('clear');
    this.$emit('formStatus', {
      value: this.isFormValid,
      customer: this.customerInfo,
    });
  },
  mounted() {
    this.validateForPefilledValues();
  },
  computed: {
    orderCreation() {
      return this.$store.state[`shop/${this.$route.params.id}`].orderCreation;
    },
    hasOrderCreationError() {
      return (
        this.orderCreation.error !== undefined &&
        this.orderCreation.error !== null
      );
    },
    enableSubmitButton() {
      return (
        !this.isFormValid ||
        this.isLoading ||
        (this.hasAnyRestrictedItems && !this.restrictionAcknowledged) ||
        this.disableSubmition
      );
    },
    fullNameRules() {
      if (!this.fieldsFilled.fullName && !this.visitedField.fullName) {
        return [];
      }
      return [
        (v) => !!v || this.$t('shop.validations.full_name_required'),
        (v) =>
          (!!v && v.length >= 5) ||
          this.$t('shop.validations.name_must_be_more_4_char'),
        (v) =>
          (!!v && /([A-Za-z])(?=\S*['-]{0,})([ a-zA-Z'-]+)/.test(v)) ||
          this.$t('shop.validations.valid_characters'),
        (v) =>
          (!!v && /([A-Za-z].+\s).+$/.test(v)) ||
          this.$t('shop.validations.must_be_two_names'),
        (v) =>
          (!!v &&
            /^(?![-'])(?!.*--)(?!.*'')[A-Za-z0-9 '-]+(?!.*[-'])$(?!.*--)$/.test(
              v
            )) ||
          this.$t('shop.validations.valid_name'),
      ];
    },
    pnrRules() {
      if (!this.customer.pnr || !this.enablePnr) {
        return [];
      }
      const rules = [];
      rules.push(
        (v) =>
          /^$|^[A-Za-z0-9]{6}$/.test(v) ||
          this.$t('shop.validations.must_be_6_chars')
      );
      return rules;
    },
    seatRules() {
      if (!this.fieldsFilled.seat && !this.visitedField.seat) {
        return [];
      }
      const rules = [];
      if (this.optionalSeatNumber) {
        rules.push(
          (v) =>
            /^$|^[A-Za-z0-9]{2,3}$/.test(v) ||
            this.$t('shop.validations.seat_must_be_2_to_3'), //Supports formats: 3D, 5e, 44D, 55b, D56, w34, D4, s5, invalid for 3DD, RR23, etc
          (v) =>
            /^$|^(0?)([1-9]\d?[a-zA-Z])$|^([a-zA-Z]0?[1-9]\d?)$/i.test(v) ||
            this.$t('shop.validations.invalid_seat_number')
        );
      } else {
        rules.push(
          (v) => !!v || this.$t('shop.validations.seat_required'),
          (v) =>
            (!!v && /^[A-Za-z0-9]{2,3}$/.test(v)) ||
            this.$t('shop.validations.seat_must_be_2_to_3'), //Supports formats: 3D, 5e, 44D, 55b, D56, w34, D4, s5, invalid for 3DD, RR23, etc
          (v) =>
            /^(0?)([1-9]\d?[a-zA-Z])$|^([a-zA-Z]0?[1-9]\d?)$/i.test(v) ||
            this.$t('shop.validations.invalid_seat_number')
        );
      }

      return rules;
    },
    emailRules() {
      if (!this.fieldsFilled.email && !this.visitedField.email) {
        return [];
      }
      return [
        (v) => !!v || this.$t('shop.validations.email_required'),
        (v) =>
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(v) ||
          this.$t('shop.validations.invalid_email'),
      ];
    },
    validate() {
      let validate = function() {
        this.$refs.checkoutForm?.validate();
      };
      validate = validate.bind(this);
      return _debounce(validate, 200);
    },
    selectedSeatColumn() {
      if (this.customer && this.customer.seat) {
        let seatCol = this.customer.seat.match(/[a-zA-Z]+/g);
        return seatCol ? seatCol[0] : null;
      } else return '';
    },
    selectedSeatNumber() {
      if (this.customer && this.customer.seat) {
        let seatNumber = this.customer.seat.match(/[0-9]+/g);
        return seatNumber ? seatNumber[0] : null;
      } else return '';
    },
    customerInfo() {
      return {
        title: '',
        firstName: this.customer.fullName.split(' ')[0] || '',
        lastName: this.customer.fullName.split(' ')[1] || '',
        email: this.customer.email || '',
        phoneNumber: '',
        seatRow: this.selectedSeatNumber || '',
        seatColumn: this.selectedSeatColumn || '',
        noSeat: this.optionalSeatNumber,
        specialInstructions: this.instructions || null,
        restrictionAcknowledged: this.restrictionAcknowledged,
        pnr: this.customer.pnr,
      };
    },
    enablePnr() {
      return this.enablePnrFF;
    },
  },
  watch: {
    ['customer.email']: {
      handler(customerEmail) {
        this.onDebouncedFieldChange(customerEmail, 'email');
      },
    },
    ['customer.fullName']: {
      handler(fullName) {
        this.onDebouncedFieldChange(fullName, 'fullName');
      },
    },
    ['customer.seat']: {
      handler(seat) {
        this.onDebouncedFieldChange(seat, 'seat');
      },
    },
    ['customer.pnr']: {
      handler(pnr) {
        this.onDebouncedFieldChange(pnr, 'pnr');
      },
    },
    restrictionAcknowledged: {
      handler(value) {
        this.isFormValid =
          this.$refs.checkoutForm.validate() &&
          this.validateCustomerFields(this.customer);
        this.$emit('formStatus', {
          value: this.isFormValid,
          customer: this.customerInfo,
        });
      },
    },
    fieldsFilled: {
      handler(newVal, oldVal) {
        if (!_isEqual(oldVal, newVal)) {
          this.validate();
        }
      },
      deep: true,
      immediate: true,
    },

    customerData: {
      immediate: true,
      handler(val) {
        if (val && val.firstName && val.lastName) {
          this.customer.fullName = `${val.firstName}` + ' ' + `${val.lastName}`;
        } else if (val.firstName) {
          this.customer.fullName = `${val.firstName}`;
        } else if (val.lastName) {
          this.customer.fullName = `${val.lastName}`;
        }
      },
    },
    isFormValid: {
      immediate: true,
      handler(val) {
        this.validate();
        this.$emit('formStatus', { value: val, customer: this.customerInfo });
      },
    },
    optionalSeatNumber() {
      // feature flag delay may cause validation to be faulty on initial load
      this.validateForPefilledValues();
    },
  },
};
</script>
<style lang="scss" scoped>
.checkout-form {
  width: 100%;
}

.seat {
  width: 20%;
  max-width: 53px;
}

::v-deep .field {
  &-input {
    @include font-size(16, 24);
    color: #27241d;

    &.v-text-field--outlined fieldset {
      border-radius: 8px;
      border: 1px solid var(--v-grey4-base);
    }

    .v-input__slot {
      min-width: 53px;
    }

    .v-text-field__details {
      padding: 0;
      min-height: auto;
      max-width: none;
    }
  }

  &-title {
    font-weight: 700;
  }
}

::v-deep .v-text-field.v-text-field--enclosed {
  .v-input__slot {
    margin-bottom: 0;
  }

  .v-text-field__details {
    min-height: auto;
    width: 300px;

    .v-messages {
      min-height: auto;
    }
  }
}

.single-text {
  @include font-size(14, 16);
  color: var(--v-grey8-base);
  margin-top: 15px;
}

::v-deep.single-line-error {
  .v-text-field__details {
    min-width: 90%;
  }
}
</style>
