<template>
  <v-container class="shop-order-summary">
    <v-row>
      <v-col>
        <span class="shop-order-summary-title">{{ $t('shop.summary') }}</span>
      </v-col>
    </v-row>
    <template v-if="hasSelectedContextFlight">
      <v-divider class="mb-1" />
      <v-row dense>
        <v-col cols="12">
          <v-icon class="mr-1">mdi-airplane</v-icon>
          <span>{{ selectedContextFlight.flightNumber }}</span>
        </v-col>
        <v-col cols="12">
          <v-icon class="mr-1">mdi-map-marker-outline</v-icon>
          <span>{{ flightPathOriginDestination }}</span>
        </v-col>
        <v-col cols="12">
          <v-icon class="mr-1">mdi-calendar</v-icon>
          <span>{{
            selectedContextFlight.departureTime | dateFormat('dddd MMMM DD')
          }}</span>
        </v-col>
      </v-row>
    </template>
    <v-divider class="my-2" />
    <v-row no-gutters>
      <v-col cols="12">
        <ShopCheckoutOrderList />
      </v-col>
    </v-row>
    <v-row v-if="!!specialInstructions">
      <v-col cols="12">
        <v-divider class="py-1" />
        <div class="size16-weight400">
          {{ $t('shop.special_instructions') }}
        </div>
        <span class="size12-weight400">{{ specialInstructions }}</span>
      </v-col>
    </v-row>
    <v-divider class="py-1" />

    <v-row no-gutters class="pb-2">
      <v-col cols="12" class="d-flex justify-space-between">
        <span>{{ $t('shop.subtotal') }}</span>
        <span>{{ orderSubTotal | currency(defaultCurrency) }}</span>
      </v-col>
    </v-row>
    <v-row v-if="discount">
      <v-col cols="12" class="d-flex justify-space-between">
        <span>{{
          $t('shop.discount_code_number', { codeLabel: discount.name })
        }}</span>
        <span>{{ discount.discountAmount | currency(defaultCurrency) }}</span>
      </v-col>
    </v-row>
    <v-divider class="py-1" />
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <span>{{
          $t('shop.total_price_with_currency', { currency: defaultCurrency })
        }}</span>
        <span class="shop-order-summary-total-price">{{
          orderTotalValue | currency(defaultCurrency)
        }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShopCheckoutOrderList from '@/modules/product/shop/features/ShopCheckout/ShopCheckoutOrderList';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';

export default {
  name: 'ShopCheckoutOrderSummary',
  components: { ShopCheckoutOrderList },
  mixins: [SelectedContextFlightMixins, ShopOrderMixins],
};
</script>

<style scoped lang="scss">
.shop-order-summary {
  background-color: var(--v-grey1-base);
  font-weight: normal;
  font-size: 16px;

  &-title {
    font-weight: bold;
    font-size: 18px;
    color: var(--v-grey8-base);
  }

  &-total-price {
    font-weight: bold;
    font-size: 20px;
    color: var(--v-grey9-base);
  }
}
</style>
