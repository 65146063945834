<template>
  <v-container class="checkout-order-list pa-0 pb-2">
    <v-row dense v-for="item in summaryCartItems" :key="item.id">
      <v-col cols="1"
        ><span class="checkout-order-list-quantity">{{
          item.quantity
        }}</span></v-col
      >
      <v-col>
        <span>{{ item.offering.name }}</span>
      </v-col>
      <v-col cols="auto">
        <span>{{
          item.price.value | currency(item.offering.price.baseCurrency)
        }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';

export default {
  name: 'ShopCheckoutOrderList',
  mixins: [ShopOrderMixins],
  computed: {
    summaryCartItems() {
      return this.cartItems.length ? this.cartItems : this.lastCreatedOrder;
    },
  },
};
</script>

<style scoped lang="scss">
.checkout-order-list {
  &-quantity {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    color: var(--v-grey9-base);
  }
}
</style>
