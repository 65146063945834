<template>
  <v-container fluid class="pa-0">
    <ShopCheckoutConfirmationModal
      :open="dialogMode"
      :lastOrder="lastCreatedOrder"
      :total="totalCost"
      :flightInfo="flight"
      :customer="customerInfo"
      :orderReferenceId="referenceNumber"
      :supplierReferenceId="supplierReference"
      :createdOn="orderDate"
      :creditCardLastDigits="last4Digits"
      :baseCurrency="defaultCurrency"
    />
    <v-row>
      <v-col cols="12" sm="8" class="pa-0">
        <ShopCheckoutConfirmationContainer
          :lastOrder="lastCreatedOrder"
          :total="totalCost"
          :flightInfo="flight"
          :customer="customerInfo"
          :orderReferenceId="referenceNumber"
          :supplierReferenceId="supplierReference"
          :createdOn="orderDate"
          :creditCardLastDigits="last4Digits"
          :baseCurrency="defaultCurrency"
        />
      </v-col>
      <v-col cols="4" v-if="$vuetify.breakpoint.mdAndUp">
        <ShopCheckoutOrderSummary />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShopCheckoutOrderSummary from '@/modules/product/shop/features/ShopCheckout/ShopCheckoutOrderSummary';
import ShopCheckoutConfirmationContainer from '@/modules/product/shop/features/ShopCheckoutConfirmation/ShopCheckoutConfirmationContainer';
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';
import moment from 'moment';
import ShopCheckoutConfirmationModal from '@/modules/product/shop/features/ShopCheckoutConfirmation/ShopCheckoutConfirmationModal';
export default {
  name: 'ShopCheckoutConfirmation',
  components: {
    ShopCheckoutConfirmationModal,
    ShopCheckoutConfirmationContainer,
    ShopCheckoutOrderSummary,
  },
  mixins: [ShopOrderMixins],
  data() {
    return {
      dialogMode: false,
    };
  },
  async beforeRouteLeave(to, from, next) {
    setTimeout(this.clearOrderState, 1000);
    next();
  },
  computed: {
    referenceNumber() {
      return this.hasCheckedOutOrder
        ? this.orderCheckout.response.referenceNumber
        : null;
    },
    supplierReference() {
      return this.hasCheckedOutOrder
        ? this.orderCheckout.response.supplierReferenceId
        : null;
    },
    orderDate() {
      return this.hasCheckedOutOrder
        ? moment(this.orderCheckout.response.createdOn).format('MMM DD, YYYY')
        : null;
    },
    last4Digits() {
      return this.hasCheckedOutOrder ? this.checkedOutOrder.last4Digits : null;
    },
  },
  watch: {
    ['$vuetify.breakpoint.smAndDown']: {
      immediate: true,
      handler(smAndDown) {
        this.dialogMode = smAndDown;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.order-placed-title {
  font-weight: 300;
  font-size: 20px;
  color: var(--v-success-base);
}
</style>
