<template>
  <div>
    <template v-for="offeringGroup in offeringGroups">
      <OfferingVariantsSelectOne
        v-if="offeringGroup.maxSelection === 1"
        :key="offeringGroup.offeringGroupId"
        :offering-group="offeringGroup"
        :show-error="showError"
        :value="variantsSelection[offeringGroup.offeringGroupId]"
        @select="onVariantSelected($event, offeringGroup.offeringGroupId)"
        :isProductPage="isProductPage"
      />
      <OfferingVariantsSelectMultiple
        v-else
        :key="offeringGroup.offeringGroupId"
        :offering-group="offeringGroup"
        :show-error="showError"
        :value="variantsSelection[offeringGroup.offeringGroupId]"
        @select="onVariantSelected($event, offeringGroup.offeringGroupId)"
        :isProductPage="isProductPage"
      />
    </template>
  </div>
</template>

<script>
import OfferingVariantsSelectOne from '@/modules/product/shop/features/OfferingVariants/OfferingVariantsSelectOne';
import OfferingVariantsSelectMultiple from '@/modules/product/shop/features/OfferingVariants/OfferingVariantsSelectMultiple';
export default {
  name: 'OfferingVariantsGroups',
  components: { OfferingVariantsSelectMultiple, OfferingVariantsSelectOne },
  data() {
    return {
      selection: {},
    };
  },
  props: {
    offeringGroups: {
      type: Array,
      default: () => [],
    },
    isProductPage: {
      type: Boolean,
      default: false,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    variantsSelection: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    variantsSelection: {
      immediate: true,
      handler(variantsSelection) {
        this.selection = variantsSelection;
      },
    },
  },
  methods: {
    onVariantSelected(variants, offeringGroupId) {
      this.selection[offeringGroupId] = variants;
      this.$emit('change', this.selection);
    },
  },
};
</script>

<style scoped></style>
