<template>
  <ShopOfferingDetailsDialog
    :is-dialog-open="dialogIsOpen"
    :offering-details="offeringItemDetails"
    :price-value="priceValue"
    :price-base-currency="priceBaseCurrency"
    :quantity="quantity"
    :max-quantity="maxQuantity"
    :show-variants-error="showVariantsError"
    :is-editing="isEditing"
    :variants-selection="variantsSelection"
    @change:quantity="quantity = $event"
    @change:variants="onVariantsChange"
    @close="onClose"
    @submit="onSubmit"
  ></ShopOfferingDetailsDialog>
</template>

<script>
import ShopOfferingDetailsDialog from '@/modules/product/shop/features/ShopOffering/ShopOfferingDetailsDialog';
import OfferingListingMixins from '@/modules/product/shop/mixins/OfferingListingMixins';
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import HotJarMixins from '@/core/mixins/HotJarMixins';
export default {
  name: 'ShopOfferingDetails',
  components: { ShopOfferingDetailsDialog },
  mixins: [
    OfferingListingMixins,
    ShopOrderMixins,
    GoogleTagManagerMixins,
    HotJarMixins,
  ],
  data() {
    return {
      quantity: 1,
      priceValue: 0,
      priceBaseCurrency: 'USD',
      variantsSelection: {},
      showVariantsError: false,
      dialogIsOpen: false,
      quantityBeforeEditing: null,
    };
  },
  props: {
    offeringId: {
      type: String,
      default: () => '',
    },
    orderId: {
      type: String,
      default: () => '',
    },
  },
  created() {
    this.calculateOrderPrice();
  },
  mounted() {
    this.pushItemViewToAnalytics();
  },
  methods: {
    pushItemViewToAnalytics() {
      let items = [
        {
          item_name: this.offering?.name,
          item_id: this.offering?.id,
          price: this.offering?.price?.value,
          quantity: this.quantity,
        },
      ];
      this.pushProductView(items);
    },
    validateVariants() {
      const hasMetLimit = (offeringGroup, selection) =>
        selection.length <= offeringGroup.maxSelection &&
        selection.length >= offeringGroup.minSelection;
      // if no variants return true, otherwise validate selection
      return (
        !this.hasVariants ||
        this.offeringItemDetails.offeringGroups.every((offeringGroup) => {
          const isRequired = offeringGroup.type === 'required';
          const selection = this.variantsSelection[
            offeringGroup.offeringGroupId
          ];

          if (Boolean(selection)) {
            // if selected, validate selection min and max
            return hasMetLimit(offeringGroup, selection);
          } else if (isRequired) {
            // not selected but required
            return false;
          } else {
            // not selected and not required
            return true;
          }
        })
      );
    },
    getVariantsAsPassesIds() {
      const passes = [];
      for (const groupOfferingId in this.variantsSelection) {
        const passIds = this.variantsSelection[groupOfferingId].map(
          (variant) => variant.id
        );
        passes.push(...passIds);
      }
      return passes;
    },
    getCurrentOrderInfo() {
      if (this.offeringItemDetails && this.offeringItemDetails.price) {
        return {
          offering: this.offeringItemDetails,
          quantity: this.quantity,
          selectedOfferingGroups: this.variantsSelection,
          passes: this.getVariantsAsPassesIds(),
          price: {
            value: this.priceValue,
            baseCurrency: this.offeringItemDetails.price.baseCurrency,
          },
        };
      }
      return null;
    },
    addOrRemoveItemAnalytics(item) {
      let changeinQuantity = (a, b) => Math.abs(a - b);
      let changeinPrice = (a, b) => Math.abs(a - b);
      let updateItemAnalytics = {
        item_name: item.offering?.name,
        item_id: item.offering?.id,
        quantity: changeinQuantity(this.quantity, this.quantityBeforeEditing),
        type:
          this.quantity >= this.quantityBeforeEditing
            ? 'Update: increase quantity'
            : 'Update: reduce quantity',
      };
      return this.pushAddToCart([updateItemAnalytics]);
    },
    onSubmit() {
      let updateItemAnalytics = null;
      if (this.quantity > 0 && this.categories.status === 'OPEN') {
        if (this.validateVariants()) {
          this.showVariantsError = false;
          const orderItem = this.getCurrentOrderInfo();

          let similarItemInCart = this.cartItems.find(
            (item) => item.offering.id === orderItem.offering.id
          );
          if (this.isEditing) {
            this.updateItemInCart(this.orderId, orderItem);
            this.addOrRemoveItemAnalytics(orderItem);
          } else if (
            !!similarItemInCart &&
            !Boolean(orderItem.offering.offeringGroups?.length > 0)
          ) {
            orderItem.quantity =
              similarItemInCart.quantity + orderItem.quantity;
            orderItem.price.value =
              similarItemInCart.price.value + orderItem.price.value;
            this.updateItemInCart(similarItemInCart.orderId, orderItem);
            this.addOrRemoveItemAnalytics(orderItem);
          } else {
            this.addItemToCart(orderItem);
            let addItemAnalytics = {
              item_name: orderItem.offering?.name,
              item_id: orderItem.offering?.id,
              quantity: orderItem.quantity,
              price: orderItem.price?.value,
            };
            this.pushAddToCart([addItemAnalytics]);
            this.pushHotJarAnalytics('add_to_cart');
          }
          this.onClose();
        } else {
          this.showVariantsError = true;
        }
      }
    },

    onClose() {
      this.dialogIsOpen = false;
      if (window.history.length > 0) {
        this.$router.go(-1);
      } else {
        this.$router
          .push({
            name: 'shop-landing',
          })
          .catch((e) => {});
      }
    },
    onVariantsChange(variantsSelection) {
      this.variantsSelection = variantsSelection;
      this.calculateOrderPrice();
    },
    calculateOrderPrice() {
      if (this.offeringItemDetails?.price) {
        let basePrice = this.offeringItemDetails.price.value;
        if (this.hasVariants) {
          let commulativeVariantsPrice = 0;
          for (const groupOfferingId in this.variantsSelection) {
            commulativeVariantsPrice += this.variantsSelection[
              groupOfferingId
            ].reduce((prev, current) => prev + current.price.value, 0);
          }
          basePrice += commulativeVariantsPrice;
        }
        this.priceValue = this.quantity * basePrice;
      } else {
        this.priceValue = 0;
      }
    },
  },
  computed: {
    isEditing() {
      return Boolean(this.cartItem);
    },
    hasVariants() {
      return Boolean(this.offeringItemDetails?.offeringGroups?.length > 0);
    },
    itemInCart() {
      let foundItems = [];
      if (this.hasCartItems) {
        foundItems = this.cartItems.filter(
          (x) => x.offering.id === this.offeringItemDetails.id
        );
      }
      if (foundItems && foundItems.length) {
        const totalNumberofOfferingsInCart = foundItems.reduce(
          (acc, o) => acc + o.quantity,
          0
        );
        return {
          status: true,
          qty: totalNumberofOfferingsInCart,
        };
      } else {
        return {
          status: false,
          qty: 0,
        };
      }
    },
    maxQuantity() {
      return this.offeringItemDetails && !this.isSoldOut
        ? this.offeringItemDetails.availableQuantity -
            (this.itemInCart.qty || 0)
        : 0;
    },
    hasDetails() {
      return Boolean(this.offeringItemDetails);
    },
    offeringItemDetails() {
      if (!this.offeringId) return null;
      return this.allCategoryOfferings.find((x) => x.id === this.offeringId);
    },
    cartItem() {
      return this.cartItems?.find((item) => item.orderId === this.orderId);
    },
  },
  watch: {
    cartItem: {
      immediate: true,
      handler(cartItem) {
        if (cartItem) {
          this.quantity = cartItem.quantity;
          this.variantsSelection = cartItem.selectedOfferingGroups;
          this.calculateOrderPrice();
        }
      },
    },
    isEditing(val, oldVal) {
      if (!oldVal && !!val) {
        this.quantityBeforeEditing = this.quantity;
      }
      if (!!oldVal && !val) {
        this.quantityBeforeEditing = null;
      }
    },
    hasDetails: {
      immediate: true,
      handler(hasDetails) {
        if (hasDetails) {
          if (!this.isEditing) {
            this.variantsSelection = {};
            this.quantity = 1;
            this.calculateOrderPrice();
          }

          this.priceBaseCurrency = this.offeringItemDetails.price.baseCurrency;
        }
        this.dialogIsOpen = hasDetails;
      },
    },
    quantity: {
      immediate: true,
      handler() {
        this.calculateOrderPrice();
      },
    },
  },
};
</script>

<style scoped></style>
