<template>
  <v-container
    class="shop-cart"
    :class="{ 'pa-0': dense }"
    data-cy="shop-cart-container"
  >
    <v-row :no-gutters="hasContextFlights">
      <v-col cols="12">
        <span class="cart-title">{{ $t('shop.your_order') }}</span>
      </v-col>
    </v-row>
    <v-row v-if="hasSelectedContextFlight" class="cart-flights-info">
      <v-col
        ><span data-cy="shop-cart-flight-information">{{
          $t('shop.for_flight', { flightAirportCodes, flightNumber })
        }}</span></v-col
      >
    </v-row>
    <template v-if="hasCartItems">
      <v-row v-if="!hideCheckout" data-cy="shop-cart-with-items">
        <v-col cols="12">
          <v-btn
            @click="onCheckout"
            block
            depressed
            large
            color="primary"
            data-cy="checkout-button"
          >
            <v-row justify="space-between">
              <v-col cols="auto"
                ><span>{{ $t('shop.checkout') }}</span></v-col
              >
              <v-col cols="auto"
                ><span>{{ totalCost | currency(defaultCurrency) }}</span></v-col
              >
            </v-row>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12 pt-0">
          <ShopCartItemsList />
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <v-divider />
      <v-row justify="center" align="center" class="cart-empty">
        <v-col cols="10"
          ><span class="d-block">{{ $t('shop.cart_is_empty') }}</span
          ><span class="d-block">{{
            $t('shop.cart_is_empty_cta')
          }}</span></v-col
        >
      </v-row>
    </template>
  </v-container>
</template>

<script>
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';
import ShopCartItemsList from '@/modules/product/shop/features/ShopCart/ShopCartItemsList';
import { ROUTE_NAMES } from '@/modules/product/shop/route';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import * as Sentry from '@sentry/vue';
import HotJarMixins from '@/core/mixins/HotJarMixins';

export default {
  name: 'ShopCart',
  components: { ShopCartItemsList },
  mixins: [
    FlightsMixins,
    SelectedContextFlightMixins,
    ShopOrderMixins,
    GoogleTagManagerMixins,
    HotJarMixins,
  ],
  props: {
    hideCheckout: {
      default: false,
      type: Boolean,
    },
    dense: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    onCheckout() {
      if (!!this.isSelectedFlightMatched()) {
        this.updateFlightMisMatchStatus(false);
        this.pushToAnalytics();
        this.$router.push({
          name: ROUTE_NAMES.SHOP_CHECKOUT,
        });
      } else {
        this.updateFlightMisMatchStatus(true);
        Sentry.captureMessage(
          'there is a mismatch betweeen flight selected and current store'
        );
        setTimeout(() => {
          this.$router.replace({
            name: ROUTE_NAMES.SHOP_FLIGHT_REDIRECT_LANDING,
            params: {
              flightNumber: this.selectedContextFlight.flightNumber,
              originCode: this.selectedContextFlight.origin.iata,
              year: this.departureYear,
              month: this.departureMonth,
              day: this.departureDay,
            },
          });
        }, 3000);
      }
    },

    isSelectedFlightMatched() {
      return (
        this.categories.storeDetails.details.id ===
        this.selectedContextFlight.id
      );
    },
    pushToAnalytics() {
      let itemsList = [];
      for (let item of this.cartItems) {
        let product = {
          item_name: item.offering?.name,
          item_id: item.offering?.id,
          price: item.offering?.price.value,
          quantity: item.quantity,
        };
        itemsList.push(product);
      }
      this.pushBeginCheckout(itemsList);
      this.pushHotJarAnalytics('begin_checkout');
    },
  },

  computed: {
    flightNumber() {
      return this.hasSelectedContextFlight
        ? this.selectedContextFlight.flightNumber
        : null;
    },
    departureYear() {
      return this.selectedContextFlight
        ? new Date(this.selectedContextFlight.departureTime)
            .getFullYear()
            .toString()
        : '';
    },
    departureMonth() {
      if (!this.selectedContextFlight) return '';
      const month =
        new Date(this.selectedContextFlight.departureTime).getMonth() + 1;
      return month.toString();
    },
    departureDay() {
      return this.selectedContextFlight
        ? new Date(this.selectedContextFlight.departureTime)
            .getDate()
            .toString()
        : '';
    },
  },
};
</script>

<style scoped lang="scss">
.shop-cart {
  padding: 0;

  @media (min-width: map-get($grid-breakpoints, md)) {
    padding: 20px 12px 0;
  }

  .cart-title {
    @include font-size(24, 150, 300);
    color: var(--v-grey9-base);
  }

  .cart-flights-info {
    @include font-size(16, 150);
    color: var(--v-grey8-base);
  }

  .cart-empty {
    min-height: 250px;
    @include font-size(16, 150);
    text-align: center;
    color: var(--v-grey8-base);
  }
}
</style>
