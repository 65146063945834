<template>
  <v-container class="pa-0">
    <v-row align="center">
      <v-col>
        <v-row no-gutters align="center">
          <v-col cols="12" sm="auto"
            ><span class="variants-title subtitle-1 font-weight-bold"
              >{{ offeringGroup.name }}
            </span></v-col
          >
          <v-col class="pl-sm-1" cols="12" sm="auto">
            <span
              v-if="shouldShowError && hasError"
              class="error-msg text-caption error--text"
            >
              {{ $t('shop.required_field') }}</span
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto">
        <v-chip small label :class="tagClass" class="px-2">
          <template v-if="tagClass === 'success'">
            <v-icon small color="#fff" class="mr-1">mdi-check</v-icon>
          </template>
          {{ tagText }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-list>
          <v-list-item-group>
            <template v-for="variant in offeringGroup.offeringVariants">
              <v-list-item
                class="px-0"
                :key="`variant-${variant.id}`"
                @click="onVariantClick(variant)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <span>{{ variant.name }}</span
                    ><span v-if="variant.price.value > 0">
                      - add
                      {{
                        variant.price.value
                          | currency(variant.price.baseCurrency)
                      }}</span
                    >
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <label class="check-container">
                    <input type="radio" :checked="variant.id === selected.id" />
                    <span class="checkmark"></span>
                  </label>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="`divider-${variant.id}`" v-if="!isProductPage" />
            </template>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'OfferingVariantsSelectOne',
  data() {
    return {
      selected: this.value.length > 0 ? this.value[0] : {},
      shouldShowError: false,
    };
  },
  props: {
    isProductPage: {
      type: Boolean,
      default: false,
    },
    offeringGroup: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Array,
      default: () => [],
    },
    showError: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showError: {
      immediate: true,
      handler(showError) {
        this.shouldShowError = showError;
      },
    },
    value: {
      immediate: true,
      handler(value) {
        if (value.length) {
          this.selected = value[0];
        }
      },
    },
    selected: {
      immediate: true,
      handler(selected) {
        const selection = [];
        if (Boolean(selected.id)) {
          selection.push(selected);
        }
        this.$emit('select', selection);
      },
    },
  },
  methods: {
    onVariantClick(variant) {
      if (!this.isRequired && variant.id === this.selected.id) {
        this.selected = {};
      } else {
        this.selected = variant;
      }
    },
  },
  computed: {
    hasError() {
      return this.isRequired && !Boolean(this.selected.id);
    },
    isRequired() {
      return this.offeringGroup.type === 'required';
    },
    tagText() {
      return this.isRequired
        ? this.$t('shop.variants.min_selection_required', { min: 1 })
        : this.$t('shop.variants.optional');
    },
    tagClass() {
      return this.isRequired
        ? Boolean(this.selected.id)
          ? 'success'
          : 'warning-secondary'
        : 'tertiary';
    },
  },
};
</script>

<style scoped lang="scss">
.variants-title {
  color: var(--v-grey9-base);
}
.error-msg {
  position: absolute;
  @media (min-width: map-get($grid-breakpoints, sm)) {
    position: inherit;
  }
}
.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: -20px;

    &:checked ~ .checkmark {
      background-color: #ffffff;

      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: -7px;
    left: 5px;
    height: 24px;
    width: 24px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 2px solid var(--v-primary-base);

    &:after {
      content: '';
      position: absolute;
      display: none;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--v-primary-base);
    }
  }
}
</style>
