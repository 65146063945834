var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{ref:`${_vm.item.id}-card`,class:[
    {
      'my-3': !_vm.$vuetify.breakpoint.smAndDown,
      'pa-3': _vm.$vuetify.breakpoint.smAndDown,
      'px-0': _vm.isSearchResultItem,
    },
  ],attrs:{"elevation":0,"disabled":_vm.isSoldOut},on:{"click":_vm.onClick}},[_c('v-row',{ref:_vm.item.id,staticClass:"shop-category-list-item",attrs:{"justify":"space-between","no-gutters":"","data-cy":"category-item-component"}},[_c('v-col',{attrs:{"cols":"9","sm":"7","md":"9"}},[_c('v-container',{staticClass:"item-container"},[_c('span',{staticClass:"d-block item-name"},[_vm._v(_vm._s(_vm.item.name || _vm.item.title))]),_c('span',{staticClass:"d-block item-subtext"},[_vm._v(_vm._s(_vm.item.description))]),(!(_vm.hasVariants && _vm.item.price.value === 0))?_c('span',{staticClass:"current-price mr-2"},[_vm._v(_vm._s(_vm._f("currency")(_vm.item.price ? _vm.item.price.value : _vm.item.price_value, _vm.item.price ? _vm.item.price.baseCurrency : _vm.item.price_currency )))]):_vm._e(),(_vm.hasOriginalPrice)?_c('span',{staticClass:"d-inline original-price"},[_vm._v(_vm._s(_vm._f("currency")(_vm.originalPrice.body,_vm.item.price.baseCurrency))+" ")]):_vm._e(),(_vm.isSoldOut)?_c('div',{staticClass:"d-inline item-subtext"},[_c('span',[_vm._v(" · ")]),_c('span',{staticClass:"error--text"},[_vm._v("Sold out")])]):_vm._e(),(_vm.itemInCart.status)?_c('div',{staticClass:"d-inline item-subtext"},[_c('span',[_vm._v(" · ")]),_c('span',{staticClass:"info--text"},[_vm._v(_vm._s(_vm.$t('shop.quantity_in_cart', { qty: _vm.itemInCart.qty })))])]):_vm._e(),(_vm.hasLowQuantity && !_vm.isSoldOut)?_c('div',{staticClass:"d-inline item-subtext"},[_c('span',[_vm._v(" · ")]),_c('span',{staticClass:"warning--text"},[_vm._v(_vm._s(_vm.$t('shop.quantity_remaining', { qty: _vm.item.availableQuantity })))])]):_vm._e()])],1),(_vm.item.image || _vm.item.thumbnail)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-img',{staticClass:"item-img",class:{ 'search-result': _vm.isSearchResultItem },attrs:{"alt":`thumbnail image for ${_vm.item.name || _vm.item.title}`,"src":_vm.item.image || _vm.item.thumbnail}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }