<template>
  <div>
    <v-btn
      small
      icon
      @click="decrement"
      :disabled="!canDecrement"
      data-cy="number-input-decrease-button"
    >
      <v-icon>mdi-minus</v-icon>
    </v-btn>
    <span class="mx-2 quantity" data-cy="number-input-quantity-count">{{
      quantity
    }}</span>
    <v-btn
      icon
      small
      @click="increment"
      :disabled="!canIncrement"
      data-cy="number-input-increase-button"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'NumberInput',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: Number.MAX_VALUE,
    },
  },
  data() {
    return {
      quantity: this.value,
    };
  },
  watch: {
    quantity: {
      immediate: true,
      handler(qty) {
        this.$emit('input', qty);
      },
    },
  },
  computed: {
    canDecrement() {
      return this.quantity > this.min;
    },
    canIncrement() {
      return this.quantity < this.max;
    },
  },
  methods: {
    increment() {
      this.quantity += 1;
    },
    decrement() {
      this.quantity -= 1;
    },
  },
};
</script>

<style scoped lang="scss">
.quantity {
  display: inline-block;
  user-select: none;
  width: 32px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}
</style>
