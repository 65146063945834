<template>
  <v-dialog v-model="dialogModel" fullscreen scrollable>
    <v-card>
      <v-card-title class="pa-2">
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text style="max-height: 90%;">
        <ShopCart hide-checkout dense />
      </v-card-text>
      <v-card-actions class="card-actions pa-0">
        <v-container class="pa-0">
          <v-btn
            @click="onCheckout"
            tile
            depressed
            color="primary"
            block
            x-large
          >
            <v-container class="pa-0">
              <v-row justify="space-between">
                <v-col cols="auto">
                  <span>{{ $t('shop.checkout') }}</span>
                </v-col>
                <v-col cols="auto">
                  <span>{{ totalCost | currency(defaultCurrency) }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-btn>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ShopCart from '@/modules/product/shop/features/ShopCart/ShopCart';
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';
import { ROUTE_NAMES } from '@/modules/product/shop/route';
export default {
  name: 'ShopCartDialog',
  components: { ShopCart },
  mixins: [ShopOrderMixins],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogModel: this.value,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(open) {
        this.dialogModel = open;
      },
    },
    dialogModel(dialogModel) {
      this.$emit('input', dialogModel);
    },
  },
  methods: {
    onCheckout() {
      this.$router.push({
        name: ROUTE_NAMES.SHOP_CHECKOUT,
      });
    },
    close() {
      this.dialogModel = false;
    },
  },
};
</script>

<style scoped lang="scss">
.card-actions {
  box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.12);
}
</style>
