<template>
  <div class="mt-6 mb-7">
    <slot name="paymentMethods" v-bind:paymentMethods="response">
      <v-container class="pa-0">
        <v-alert v-if="error" type="error" transition="bounceInRight">
          {{ error }}
        </v-alert>
        <div class="payment-info mb-8 pt-6">
          <div class="size18-weight700 mb-2">
            {{ $t('product.payment_methods') }}
          </div>
          <PaymentsList
            :savedCreditCards="savedCards"
            :methods="paymentMethods"
            :amount="createdOrderAmount"
            @create-alipayment="onAliButtonClick"
            @error="onError"
            @loading="onloading"
            @change="onListChange"
            @changeSavedCreditCardsSelection="onSavedCreditCardsSelection"
          />
        </div>

        <v-row no-gutters class="mb-8 px-5 px-md-0">
          <v-col cols="12" md="8">
            <PromoCodeForm
              dense
              :order-id="orderId"
              :discountCoupon="discount"
              @submit:discountCoupon="updateDiscount"
              @clear:discountCoupon="removeDiscount"
              type="shop"
            />
          </v-col>
        </v-row>

        <TermsAndConditionsLink />

        <v-row
          justify="space-between"
          align="center"
          class="ma-0 payment-return-wrapper px-5 px-md-0"
        >
          <v-btn
            :loading="isLoading || isPolling"
            :disabled="!enableButton"
            class="primary size20-weight700 payment-button"
            data-cy="checkout-button"
            justify="end"
            @click="onPaymentButtonClick"
          >
            {{ $t('product.confirm_and_pay') }}
            {{
              createdOrderAmount.value
                | currency(createdOrderAmount.baseCurrency)
            }}
            {{ createdOrderAmount.baseCurrency }}
          </v-btn>
        </v-row>
      </v-container>
    </slot>
  </div>
</template>

<script>
import { RepoFactory } from '@/core/repositories';
import PaymentsList from '@/modules/product/features/Payment/PaymentsList';
import PaymentMixins from '@/modules/payment/mixins/PaymentMixins';
import PromoCodeForm from '@/core/components/PromoCodeForm';
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';
import TermsAndConditionsLink from '@/core/components/TermsAndConditionsLink';

export default {
  name: 'ShopPaymentMethodsContainer',
  mixins: [PaymentMixins, ShopOrderMixins],
  components: { PaymentsList, PromoCodeForm, TermsAndConditionsLink },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    orderId: String,
    clientConfirmationKey: String,
    isPolling: Boolean,
  },
  data() {
    return {
      savePaymentMethodId: false,
      selectedMethod: null,
      selectedSavedCreditCard: null,
      selectedMethodExtras: null,
      allowedMethods: [
        'CreditCard',
        'AliPay',
        'WeChat',
        'ApplePay',
        'GooglePay',
      ],
      isLoadingInternal: false,
      response: null,
      error: null,
      enableButton: false,
      stripeCard: null,
      paymentConfirmationError: null,
      paymentMethodId: null,
      isAliPayMethodSelected: false,
    };
  },
  watch: {
    orderId: {
      immediate: true,
      handler(orderId, oldOrderId) {
        if (!!orderId && orderId !== '') {
          this.loadPaymentMethods(orderId);
          this.loadSavedCreditCards();
        }
      },
    },
    clientConfirmationKey(newValue) {
      if (newValue) {
        this.confirmCreditCardPayment(newValue);
      }
    },
    isAliPayMethodSelected(newValue) {
      if (newValue) {
        this.onAliButtonClick();
      }
    },
  },
  methods: {
    enablePersistence() {
      this.$store.dispatch(`featureFlags/enablePersistence`);
    },
    onError(error) {
      this.error = error;
    },
    onloading(isLoading) {
      this.isLoadingInternal = isLoading;
    },
    onSavedCreditCardsSelection(card) {
      this.selectedSavedCreditCard = card;
      if (this.selectedMethod === null) {
        this.enableButton = !!card;
      }
    },
    onListChange(method, extras) {
      if (method != null && extras != null) {
        this.selectedMethod = method;
        this.selectedMethodExtras = extras;
        this.enableButton = extras.completed;
        this.savePaymentMethodId = extras.savePaymentMethodId;
        this.isAliPayMethodSelected = !!extras.source?.alipay;
      } else {
        this.selectedMethod = null;
        this.selectedMethodExtras = null;
        this.enableButton = !!this.selectedSavedCreditCard;
        this.savePaymentMethodId = false;
      }
      if (extras?.id) {
        this.onPaymentButtonClick();
      }
    },
    loadSavedCreditCards(travelerId = this.travelerId) {
      if (travelerId) {
        this.loadSavedPaymentMethods(travelerId);
      }
    },
    loadPaymentMethods(orderId) {
      const orderService = RepoFactory.get('order', {
        resourceId: orderId,
      });
      this.loadPaymentMethodsFromService(orderService);
    },
    async loadPaymentMethodsFromService(orderService) {
      this.isLoadingInternal = true;
      try {
        const { data } = await orderService.getPaymentMethods();
        this.response = data;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isLoadingInternal = false;
      }
    },
    onPaymentButtonClick() {
      this.error = null;
      this.paymentConfirmationError = null;
      // declare handlers obj for switching method handler
      const handlers = {
        CreditCard: this.creditCardClickHandler,
        GooglePay: this.submitCreditCardPayment,
        ApplePay: this.submitCreditCardPayment,
      };
      if (this.selectedMethod && handlers[this.selectedMethod]) {
        handlers[this.selectedMethod](this.selectedMethodExtras); // invoke the corresponding handler
      } else if (this.selectedSavedCreditCard) {
        this.$emit(
          'paymentConfirmed',
          this.selectedSavedCreditCard['paymentMethodId'],
          false
        );
      }
    },
    onAliButtonClick() {
      this.paymentConfirmationError = null;
      if (this.selectedMethodExtras) {
        this.aliPayClickHandler(this.selectedMethodExtras);
      }
    },
    creditCardClickHandler({ card, id }) {
      if (this.clientConfirmationKey != null) {
        this.confirmCreditCardPayment();
      } else {
        this.submitCreditCardPayment({ card, id });
      }
    },
    async confirmCreditCardPayment() {
      this.isLoadingInternal = true;
      try {
        const { error } = await this.$stripe.lib.handleCardAction(
          this.clientConfirmationKey
        );
        if (error) {
          this.$emit('paymentConfirmationFailed');
          this.error = this.$t('product.unable_to_confirm_payment_error');
        } else {
          this.$emit(
            'paymentConfirmed',
            this.paymentMethodId,
            this.savePaymentMethodId
          );
        }
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isLoadingInternal = false;
      }
    },
    async submitCreditCardPayment({ card, id }) {
      this.isLoadingInternal = true;
      const isRetry = !!this.paymentMethodId;
      let paymentMethod, error;
      try {
        if (!id) {
          ({
            paymentMethod,
            error,
          } = await this.$stripe.lib.createPaymentMethod('card', card));
        }
        if (error) {
          this.error = error;
        } else {
          this.paymentMethodId = paymentMethod?.id ?? id;
          this.$emit('paymentMethodIdCreated', {
            paymentMethodId: this.paymentMethodId,
            requiresOrderCreation: isRetry,
            savePaymentMethodId: this.savePaymentMethodId,
          });
        }
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isLoadingInternal = false;
      }
    },
    aliPayClickHandler({ source }) {
      this.enablePersistence();
      this.$emit('paymentMethodIdCreated', {
        paymentMethodId: `AliPay-${source.id}`,
        requiresOrderCreation: this.error,
        paymentSource: source,
        shouldRedirectTo: source.redirect.url,
      });
    },
  },
  computed: {
    isLoading() {
      return (this.isLoadingInternal || this.loading) && !this.error;
    },
    paymentMethods() {
      return !!this.response
        ? this.response.filter(
            (method) => this.allowedMethods.indexOf(method) > -1
          )
        : [];
    },
    hasPaymentError() {
      return !!this.paymentConfirmationError;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .payment {
  &-button {
    padding: 12px 20px !important;
    border-radius: 8px;
    height: 54px !important;
    letter-spacing: 0px;
  }
  &-info {
    padding: 20px;
    background: var(--v-grey1-base);
    border-radius: 4px;

    @media (max-width: map-get($grid-breakpoints, 'sm')) {
      margin-bottom: 0;
    }
  }

  &-return {
    &-wrapper {
      @media (max-width: map-get($grid-breakpoints, 'sm')) {
        flex-direction: column-reverse;

        & > button {
          width: 100%;
          margin-bottom: 32px;
        }
      }
    }

    &-link {
      color: var(--v-grey9-base);
      text-decoration: none;
    }
  }
}
</style>
