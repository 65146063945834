<template>
  <v-dialog
    id="scrolltarget"
    :scrollable="false"
    :retain-focus="false"
    v-on:click:outside="goBack"
    @keydown.esc="goBack"
    v-model="displayModal"
    overlay-color="#fff"
    overlay-opacity="0.6"
    :height="$vuetify.breakpoint.smAndDown ? productHeight : 'auto'"
  >
    <v-row no-gutters class="pa-0 product-detail" @scroll="onScroll">
      <transition name="slide">
        <p v-show="isHideTitle" v-if="hasTitle" class="product-title">
          {{ offering.name }}
          <v-icon @click="goBack" class="close-icon"> mdi-close </v-icon>
        </p>
      </transition>
      <div
        class="product-content"
        :style="$vuetify.breakpoint.smAndDown ? `height:${productHeight}` : ''"
      >
        <v-col
          cols="12"
          class="pa-0 product-topinfo"
          :class="{ 'pb-4 mb-6': offering && !offering.image }"
        >
          <v-skeleton-loader v-if="loading" type="image" :loading="loading" />
          <div class="product-image" v-if="hasImage">
            <v-img
              alt="close icon"
              :cover="$vuetify.breakpoint.mdAndUp"
              :contain="$vuetify.breakpoint.mdAndDown"
              :class="{ 'sold-out-image': isSoldOut }"
              :src="offering.image"
              :max-height="$vuetify.breakpoint.mdAndDown ? '250px' : '350px'"
              max-width="100%"
            />

            <v-icon @click="goBack" class="close-icon"> mdi-close </v-icon>
          </div>
          <p v-if="hasTitle && !offering.image" class="product-title">
            {{ offering.name }}
            <v-icon @click="goBack" class="close-icon"> mdi-close</v-icon>
          </p>
        </v-col>

        <v-col cols="12" class="product-information">
          <v-skeleton-loader v-if="loading" type="article" :loading="loading" />
          <p v-if="hasTitle" class="title">
            {{ offering.name }}
          </p>
          <div v-if="hasPrice">
            <span class="d-block current-price">
              {{ offering.price.value | currency(offering.price.baseCurrency) }}
            </span>
            <span v-if="hasOriginalPrice" class="d-block original-price">
              {{ originalPrice.body | currency(offering.price.baseCurrency) }}
            </span>
            <span
              v-if="hasReducedAmount"
              class="d-block red--text size14-weight600"
            >
              {{
                $t('shop.you_saved', {
                  reduced_amount:
                    reducedAmount.body | currency(offering.price.baseCurrency),
                })
              }}
            </span>
          </div>
          <p v-if="hasDescription" class="mt-3 description">
            {{ offering.description }}
          </p>
          <template v-if="containsInformation">
            <p
              class="pt-2"
              v-for="(info, key) in filteredOfferingsInformation"
              :key="key"
            >
              <template v-if="info.title">
                <span class="d-block font-weight-bold">
                  {{ info.title }}
                </span>
              </template>
              <template v-if="info.body">
                <span class="d-block">{{ info.body }}</span>
              </template>
            </p>
          </template>
          <OfferingVariantsGroups
            class="product-information-variants"
            v-if="hasVariants"
            :offering-groups="offering.offeringGroups"
            :show-error="showVariantsError"
            @change="onVariantsChange"
          />
        </v-col>
      </div>

      <v-col cols="12" class="product-additional">
        <div class="product-additional__quantity">
          <ShopQuantityField
            v-model.number="quantity"
            @input="onChange"
            @load="setDefaultQuantity"
            :itemInStore="!isSoldOut"
            :max="maxQuantity"
            :min="minQuantity"
          />
        </div>
        <div
          data-cy="add-to-cart-button"
          :class="[
            'product-additional-cart_button',
            categories.status === 'OPEN' ? 'active' : 'disabled',
          ]"
          @click="isSoldOut ? goBack() : onSubmit()"
        >
          <template v-if="isSoldOut">
            {{ $t('shop.back_to_menu') }}
          </template>
          <template v-else>
            {{ $t('shop.add_to_cart', { q: quantity }) }}
            <span v-if="hasPrice" class="selected-price">
              {{ displayedOrderPrice | currency(offering.price.baseCurrency) }}
            </span>
          </template>
        </div>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
import ShopMixins from './../mixins/ShopMixins.vue';
import ShopOrderMixin from '../mixins/ShopOrderMixins.vue';
import ShopQuantityField from './ShopQuantityField.vue';
import OfferingListingMixins from './../mixins/OfferingListingMixins.vue';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import OfferingVariantsGroups from '@/modules/product/shop/features/OfferingVariants/OfferingVariantsGroups';
import HotJarMixins from '@/core/mixins/HotJarMixins';
export default {
  name: 'OfferingDetail',
  mixins: [
    ShopMixins,
    OfferingListingMixins,
    ShopOrderMixin,
    GoogleTagManagerMixins,
    HotJarMixins,
  ],
  components: { OfferingVariantsGroups, ShopQuantityField },
  props: {
    offeringId: String,
  },
  data() {
    return {
      variantsSelection: {},
      showVariantsError: false,
      isHideTitle: false,
      displayModal: false,
      quantity: 1,
      orderPrice: 0,
      prevRoute: null,
      productHeight: '85vh',
      image: {
        height: '',
        width: '',
      },
      loading: true,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  watch: {
    quantity: {
      immediate: true,
      handler() {
        if (this.offering) this.calculateOrderPrice();
      },
    },
    offering: {
      immediate: true,
      handler(val, oldVal) {
        if (val !== oldVal && this.orderPrice === 0) {
          this.calculateOrderPrice();
        }
        if (!oldVal && !!val) {
          this.loading = false;
        }
      },
    },
    displayModal(visible) {
      if (visible && this.offering) {
        this.getProductBlockHeight(this.offering.image);
      }
    },
    $route: {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.displayModal = val.meta && val.meta.showModal;
        }
      },
    },
  },
  destroyed() {
    document.body.removeAttribute('class', 'noscroll');
  },
  created() {
    document.getElementsByTagName('body')[0].className = 'noscroll';
    this.clearOrderItem();
    this.pushItemViewToAnalytics();
  },
  methods: {
    onVariantsChange(variantsSelection) {
      this.variantsSelection = variantsSelection;
      this.calculateOrderPrice();
    },
    addImageLoadListener(url) {
      var img = new Image();
      img.addEventListener('load', (el) => {});
      img.src = url;

      return { height: img.naturalHeight, width: img.naturalWidth };
    },
    getProductBlockHeight(image) {
      // get sizes of real image
      const { height, width } = this.addImageLoadListener(image);
      let productInfoHeight = document.getElementsByClassName(
        'product-information'
      )[0].clientHeight;
      let bodyWidth = document.getElementsByTagName('body')[0].offsetWidth;

      // get height of rendered image
      let imageBlockHeight = (height / width) * bodyWidth;

      // get height of the product block
      let containerHeight = imageBlockHeight + productInfoHeight;

      // calculating the ratio of the size of the product block to the height of the device
      let percentHeight = (containerHeight / window.screen.height) * 100;

      if (percentHeight < 85) {
        this.productHeight = `${containerHeight}px`;
      } else {
        this.productHeight = '85vh';
      }
    },
    onScroll(el) {
      if (el.srcElement.scrollTop >= 10) {
        this.isHideTitle = true;
      } else {
        this.isHideTitle = false;
      }
    },
    getCurrentOffering(arr) {
      return arr.find((x) => x.id == this.$route.params.offeringId);
    },
    setDefaultQuantity(val) {
      this.quantity = val;
    },
    goBack() {
      this.clearSelectedOffering();
      this.displayModal = false;
      if (this.prevRoute && this.prevRoute.name === 'shop-landing') {
        this.$router.go(-1);
      } else if (!!this.$route.params.id) {
        this.$router
          .push({
            name: 'shop-landing',
            params: { id: this.$route.params.id },
          })
          .catch((e) => {});
      } else {
        this.$router.push({ name: 'booking' });
      }
    },
    onChange() {
      this.setCurrentOrderItem(this.getCurrentOrderInfo());
    },
    validateVariants() {
      const hasMetLimit = (offeringGroup, selection) =>
        selection.length <= offeringGroup.maxSelection &&
        selection.length >= offeringGroup.minSelection;
      // if no variants return true, otherwise validate selection
      return (
        !this.hasVariants ||
        this.offering.offeringGroups.every((offeringGroup) => {
          const isRequired = offeringGroup.type === 'required';
          const selection = this.variantsSelection[
            offeringGroup.offeringGroupId
          ];

          if (Boolean(selection)) {
            // if selected, validate selection min and max
            return hasMetLimit(offeringGroup, selection);
          } else if (isRequired) {
            // not selected but required
            return false;
          } else {
            // not selected and not required
            return true;
          }
        })
      );
    },
    onSubmit() {
      if (this.quantity > 0 && this.categories.status === 'OPEN') {
        if (this.validateVariants()) {
          this.showVariantsError = false;
          let orderItem = this.getCurrentOrderInfo();
          let similarItemInCart = this.cartItems.find(
            (item) => item.offering.id === orderItem.offering.id
          );
          if (
            !!similarItemInCart &&
            !Boolean(orderItem.offering.offeringGroups?.length > 0)
          ) {
            orderItem.quantity =
              similarItemInCart.quantity + orderItem.quantity;
            orderItem.price.value =
              similarItemInCart.price.value + orderItem.price.value;
            this.updateItemInCart(similarItemInCart.orderId, orderItem);
          } else this.addItemToCart(orderItem);
          this.goBack();
        } else {
          this.showVariantsError = true;
        }
      }
      this.pushAddToCartToAnalytics();
    },
    pushAddToCartToAnalytics() {
      const items = [
        {
          item_name: this.offering?.name,
          item_id: this.offering?.id,
          price: this.offering?.price.value,
          quantity: this.quantity,
        },
      ];
      const cartInfo = {
        currency: this.offering?.price.baseCurrency,
        value: this.offering?.price.value,
        items,
      };
      this.pushAddToCart(cartInfo);
      this.pushHotJarAnalytics('add_to_cart');
    },
    pushItemViewToAnalytics() {
      let items = [
        {
          item_name: this.offering?.name,
          item_id: this.offering?.id,
          price: this.offering?.price?.value,
          quantity: this.quantity,
        },
      ];
      this.pushProductView(items);
      this.pushHotJarAnalytics('view_item');
    },
    calculateOrderPrice() {
      if (this.offering && this.offering.price) {
        let basePrice = this.offering.price.value;
        if (this.hasVariants) {
          let commulativeVariantsPrice = 0;
          for (const groupOfferingId in this.variantsSelection) {
            commulativeVariantsPrice += this.variantsSelection[
              groupOfferingId
            ].reduce((prev, current) => prev + current.price.value, 0);
          }
          basePrice += commulativeVariantsPrice;
        }
        this.orderPrice = this.quantity * basePrice;
      } else {
        this.orderPrice = 0;
      }
    },
    getVariantsAsPassesIds() {
      const passes = [];
      for (const groupOfferingId in this.variantsSelection) {
        const passIds = this.variantsSelection[groupOfferingId].map(
          (variant) => variant.id
        );
        passes.push(...passIds);
      }
      return passes;
    },
    getCurrentOrderInfo() {
      if (this.offering && this.offering.price) {
        return {
          offering: this.offering,
          quantity: this.quantity,
          selectedOfferingGroups: this.variantsSelection,
          passes: this.getVariantsAsPassesIds(),
          price: {
            value: this.orderPrice,
            baseCurrency: this.offering.price.baseCurrency,
          },
        };
      }
      return null;
    },
  },
  computed: {
    originalPrice() {
      return this.offering.information?.find(
        (info) => info.title === 'original_price'
      );
    },
    hasOriginalPrice() {
      return Boolean(this.originalPrice);
    },
    hasReducedAmount() {
      return Boolean(this.reducedAmount);
    },
    reducedAmount() {
      return this.offering.information?.find(
        (info) => info.title === 'reduced_amount'
      );
    },
    filteredOfferingsInformation() {
      const bList = ['original_price', 'reduced_amount'];
      return this.offering.information?.filter(
        (info) => bList.indexOf(info.title) < 0
      );
    },
    offering() {
      return this.selectedOffering || this.matchedOffering;
    },
    hasVariants() {
      return Boolean(this.offering?.offeringGroups?.length > 0);
    },
    hasImage() {
      return this.offering && this.offering.image;
    },
    hasTitle() {
      return this.offering && this.offering.name;
    },
    hasPrice() {
      return this.offering && this.offering.price;
    },
    hasDescription() {
      return this.offering && this.offering.description;
    },
    matchedOffering() {
      return this.getCurrentOffering(this.allCategoryOfferings);
    },
    itemInCart() {
      let foundItems = [];
      if (this.hasCartItems) {
        foundItems = this.cartItems.filter(
          (x) => x.offering.id === this.offering.id
        );
      }
      if (foundItems && foundItems.length) {
        const totalNumberofOfferingsInCart = foundItems.reduce(
          (acc, o) => acc + o.quantity,
          0
        );
        return {
          status: true,
          qty: totalNumberofOfferingsInCart,
        };
      } else {
        return {
          status: false,
        };
      }
    },
    displayedOrderPrice() {
      return parseFloat(this.orderPrice).toFixed(2);
    },
    containsInformation() {
      return (
        !!this.offering &&
        !!this.offering.information &&
        !!this.offering.information.length
      );
    },
    maxQuantity() {
      return this.offering && !this.isSoldOut
        ? this.offering.availableQuantity - (this.itemInCart.qty || 0)
        : 0;
    },
    minQuantity() {
      return !this.isSoldOut ? 1 : 0;
    },
    isSoldOut() {
      if (
        (this.offering && this.offering.availableQuantity) ||
        (this.offering && this.offering.isAvailable)
      ) {
        return (
          this.offering.availableQuantity - (this.itemInCart.qty || 0) == 0 ||
          this.offering.isAvailable == false
        );
      }
      return null;
    },
    buttonStatusClass() {
      return this.categories.status === 'OPEN'
        ? 'action-btn'
        : 'action-btn-disabled';
    },
    modalSizeClass() {
      return this.$vuetify.breakpoint.mdAndUp ? 'half-width' : 'full-width';
    },
  },
};
</script>
<style lang="scss" scoped>
.sold-out {
  color: #ff4d4f;
  font-weight: bold;
}
.sold-out-image {
  opacity: 0.2;
}

::v-deep .v-dialog {
  border-radius: 8px;
  margin: auto auto 0;
  position: relative;
  width: 100vw;
  overflow-y: visible;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    max-width: 546px;
    height: auto;
    max-height: 85vh;
    margin: auto;
  }
}

.v-dialog__content {
  backdrop-filter: blur(1px);
}

.product {
  &-detail {
    background-color: #fff;
    height: initial;
    overflow-y: scroll;
    position: fixed;
    bottom: 0;
    border-radius: 8px 8px 0 0;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      max-height: 85vh;
      position: relative;
      border-radius: 8px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-content {
    position: relative;
    width: 100%;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      height: 100%;
    }
  }

  &-topinfo {
    position: relative;
  }

  &-additional {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    height: 130px;
    padding: 0;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      max-width: 546px;
      border-radius: 8px;
      overflow: hidden;
      position: sticky;
    }

    &-cart_button {
      display: flex;
      justify-content: center;
      width: 100%;
      background: #3470dd;
      @include font-size(16, 19, 600);
      color: #fff;
      padding: 15px 0 22px;
      position: relative;
      cursor: pointer;

      &.disabled {
        background: #cfd5e0;
        cursor: not-allowed;
      }
    }

    &__quantity {
      display: flex;
      justify-content: center;
      @include font-size(24, 28);
      color: var(--v-grey8-base);
      margin-bottom: 8px;
    }
  }

  &-title {
    @include font-size(16, 19, 600);
    text-align: center;
    color: var(--v-grey8-base);
    position: fixed;
    padding: 11px 0;
    background: #fff;
    box-shadow: 0px 4px 12px rgba(21, 30, 77, 0.04);
    border-bottom: 1px solid #e4e8ef;
    border-radius: 8px 8px 0 0;
    z-index: 9;
    width: 100%;
    max-width: 546px;

    &.top-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 40px;
      padding-left: 40px;
    }
  }

  &-image {
    position: relative;
  }

  &-information {
    padding: 24px 16px 130px;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      padding: 24px 16px 0;
    }

    &-variants {
      padding-bottom: 0px;

      @media (min-width: map-get($grid-breakpoints, sm)) {
        padding-bottom: 130px;
      }
    }

    .price,
    .title {
      margin-bottom: 0;
    }

    .description,
    .price {
      @include font-size(18, 23);
      color: var(--v-grey7-base);
    }

    .title {
      @include font-size(24, 28, 600);
      color: var(--v-grey8-base);
      margin-bottom: 4px;
    }

    .price {
      margin-bottom: 16px;
    }

    .description {
      margin-bottom: 0;
    }
  }
}

::v-deep .product-additional__quantity .v-text-field input {
  @include font-size(24, 28);
}

::v-deep .product-additional__quantity .v-text-field__details {
  display: none;
}

.close-icon {
  position: absolute;
  right: 15px;
  top: 8px;
  border-radius: 8px;
  background-color: #fff;
}

.selected-price {
  position: absolute;
  right: 16px;
  top: 15px;
}
.suppress-x-scroll {
  overflow-x: hidden;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.1s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
}
.original-price {
  font-size: 14px;
  font-weight: 500;
  color: var(--v-grey7-base);
  text-decoration: line-through;
}
.current-price {
  font-size: 20px;
  font-weight: 600;
  color: var(--v-grey8-base);
}
</style>
