<template>
  <v-container class="px-0 py-2">
    <v-row
      no-gutters
      justify="start"
      justify-md="end"
      align="end"
      class="step-section"
    >
      <v-col
        @click="$emit('goBackToForm')"
        cols="auto"
        :class="{ active: state === 'forms', clickable: state !== 'forms' }"
      >
        <v-icon small :color="state === 'forms' ? 'primary' : ''"
          >mdi-account-circle-outline</v-icon
        >
        <span>{{ $t('shop.your_information') }}</span>
      </v-col>
      <v-col cols="auto" class="px-1">—</v-col>
      <v-col cols="auto" :class="{ active: state === 'payment' }">
        <v-icon small :color="state === 'payment' ? 'primary' : ''"
          >mdi-credit-card-outline</v-icon
        >
        <span> {{ $t('shop.payment_and_review') }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ShopCheckoutStatesStepper',
  props: {
    state: {
      type: String,
      default: () => 'forms',
      validator: function(value) {
        return ['forms', 'payment'].includes(value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.step-section {
  font-weight: normal;
  font-size: 14px;
  color: var(--v-grey7-base);
  cursor: default;
  .active {
    font-weight: bold;
    color: var(--v-grey9-base);
  }
}

.clickable {
  cursor: pointer;
}
</style>
