<script>
import Vue from 'vue';
import ShopMixins from './ShopMixins.vue';
import { PusherPlugin } from '@/plugins/PusherPlugin';

export default {
  name: 'RealtimeMixins',
  mixins: [ShopMixins],
  methods: {
    initPusher() {
      Vue.use(PusherPlugin);
      this.$pusher.initialize(this.$store.state.configs.pusher_api_key);
    },
    subscribe(channelName) {
      if (!this.$pusher) {
        this.initPusher();
      }
      this.channel = this.$pusher.subscribe(channelName);
      this.bindEvents();
    },

    unsubscribe() {
      if (this.channel) {
        this.channel.unsubscribe();
      }
    },
    bindEvents() {
      if (this.channel) {
        this.channel.bind('STORE_UPDATED', this.modifyStore);
      }
    },

    modifyStore(storeDetails) {
      this.$store.dispatch(
        `${this.storeModuleName}/modifyStoreStatus`,
        storeDetails
      );
    },

    beforeDestroy() {
      this.unsubscribe();
    },

    subscribeRealtimeActionsToNewFlight(channelName) {
      this.unsubscribe();
      this.subscribe(channelName);
    },
  },
};
</script>
