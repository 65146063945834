<template>
  <v-dialog v-model="dialogModel" fullscreen scrollable>
    <v-card>
      <v-card-title class="pa-2">
        <v-btn icon @click="goHome">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <ShopCheckoutConfirmationContainer
          :lastOrder="lastOrder"
          :total="total"
          :flightInfo="flightInfo"
          :customer="customer"
          :orderReferenceId="orderReferenceId"
          :supplierReferenceId="supplierReferenceId"
          :createdOn="createdOn"
          :creditCardLastDigits="creditCardLastDigits"
          :baseCurrency="baseCurrency"
          show-total
          mobile
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ShopCheckoutConfirmationContainer from '@/modules/product/shop/features/ShopCheckoutConfirmation/ShopCheckoutConfirmationContainer';
import { ROUTE_NAMES } from '@/modules/product/shop/route';
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';
export default {
  name: 'ShopCheckoutConfirmationModal',
  components: { ShopCheckoutConfirmationContainer },
  mixins: [ShopOrderMixins],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    lastOrder: Array,
    total: Number,
    flightInfo: Object,
    customer: Object,
    orderReferenceId: String,
    supplierReferenceId: String,
    createdOn: String,
    baseCurrency: String,
    creditCardLastDigits: String,
  },
  data() {
    return {
      dialogModel: false,
    };
  },
  methods: {
    goHome() {
      this.$router.push({ name: ROUTE_NAMES.SHOP_LANDING });
      this.clearCart();
    },
  },
  watch: {
    open: {
      immediate: true,
      handler(open) {
        this.dialogModel = open;
      },
    },
  },
};
</script>

<style scoped></style>
