<template>
  <v-container class="pa-0">
    <div v-if="showTopShadow" class="top-shadow" style="height: 4px"></div>
    <div
      v-scroll.self="onScroll"
      class="overflow-x-hidden overflow-y-auto always-show-scrollbar"
      :style="
        $vuetify.breakpoint.mdAndUp
          ? 'max-height:' + scrollableContentHeight + 'px'
          : ''
      "
    >
      <template v-for="(item, i) in cartItems">
        <v-row no-gutters :key="item.id" :data-cy="`shop-cart-item-${i}`">
          <v-col cols="12">
            <ShopCartItem
              @edit="onEdit(item)"
              @remove="onRemove(item)"
              :item="item"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-3 mb-1" :key="i" />
      </template>
      <div v-if="specialInstructionsFF" class="py-4">
        <v-row
          v-if="!isSpecialInstructionFieldShowing"
          class="pl-1"
          @click="showSpecialInstructionField()"
        >
          <v-icon size="20" class="px-3" color="primary">mdi-plus</v-icon>
          <span class="special-instruction-button">
            {{ $t('shop.add_special_instructions') }}
          </span>
        </v-row>
        <div v-else>
          <span class="d-block size14-weight600 pb-2">
            {{ $t('shop.special_instructions') }}
          </span>
          <v-text-field
            name="instructions"
            v-model="instructions"
            @blur="updateSpecialInstructions(instructions)"
            outlined
            dense
            counter="255"
            :label="$t('shop.type_here')"
          />
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';
import ShopCartItem from '@/modules/product/shop/features/ShopCart/ShopCartItem';
import OfferingListingMixins from '@/modules/product/shop/mixins/OfferingListingMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import { ROUTE_NAMES } from '@/modules/product/shop/route';

export default {
  name: 'ShopCartItemsList',
  components: { ShopCartItem },
  mixins: [ShopOrderMixins, OfferingListingMixins, GoogleTagManagerMixins],
  created() {
    this.checkSpecialInstructionsFF();
  },
  data() {
    return {
      scrollTop: 0,
      specialInstructionsFF: false,
      isSpecialInstructionFieldShowing: false,
      instructions: '',
    };
  },
  computed: {
    showTopShadow() {
      return this.scrollTop > 10;
    },
    scrollableContentHeight() {
      return this.$vuetify.breakpoint.height - 400;
    },
  },
  methods: {
    onRemove(item) {
      this.removeItemFromCart(item.orderId);
      this.pushRemoveToAnalytics(item);
    },
    onEdit(item) {
      this.$router.push({
        name: ROUTE_NAMES.SHOP_PRODUCT_DETAILS,
        params: {
          id: this.$route.params.id,
          offeringId: item.offering.id,
          orderId: item.orderId,
        },
      });
    },
    pushRemoveToAnalytics(item) {
      let items = [
        {
          item_name: item.offering.name,
          item_id: item.offering.id,
          price: item.offering.price.value,
          quantity: item.quantity,
        },
      ];
      this.pushRemoveFromCart(items);
    },
    onScroll(e) {
      this.scrollTop = e.target.scrollTop;
    },
    async checkSpecialInstructionsFF() {
      this.specialInstructionsFF = await this.$ldclient.variation(
        'shop-special-instructions',
        false,
        true
      );
      this.$ldclient.$on('change:shop-special-instructions', (value) => {
        this.specialInstructionsFF = value;
      });
    },
    showSpecialInstructionField() {
      this.isSpecialInstructionFieldShowing = true;
    },
  },
};
</script>

<style scoped lang="scss">
/* Hide scrollbar for Chrome, Safari and Opera */
.always-show-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
}
.always-show-scrollbar::-webkit-scrollbar:vertical {
  width: 18px;
}
.always-show-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 6px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.1);
}

.always-show-scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

.top-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.special-instruction-button {
  cursor: pointer;
  @include font-size(14, 150, 400);
  color: var(--v-primary-base);
}
</style>
