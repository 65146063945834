<template>
  <div class="d-flex justify-space-around sliderContainer">
    <v-carousel
      v-model="currentPage"
      :height="maxHeight"
      show-arrows-on-hover
      hide-delimiter-background
      hide-delimiters
      :show-arrows="$vuetify.breakpoint.mdAndUp && imageUrls.length > 1"
    >
      <v-carousel-item
        v-for="(item, i) in imageUrls"
        :key="i"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <v-img
          :src="item"
          contain
          aspect-ratio="6 / 4"
          class="d-flex flex-column justify-center align-center fill-height"
          alt=""
        />
      </v-carousel-item>
    </v-carousel>

    <template v-if="imageUrls.length > 1">
      <div class="d-flex align-center delimiterContainer">
        <v-img
          width="24px"
          height="24px"
          v-for="index in imageUrls"
          alt="delimiter"
          :key="index"
          @click="currentPage = index"
          :src="currentPage === index ? activeDelimiter : inactiveDelimiter"
        />
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'ImageSliderComponent',
  props: {
    maxHeight: {
      type: Number,
      default: 250,
    },
    imageUrls: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeDelimiter: require('@/assets/delimiter-active.png'),
      inactiveDelimiter: require('@/assets/delimiter-inactive.png'),
      currentPage: 0,
    };
  },
};
</script>
<style scoped lang="scss">
.sliderContainer {
  position: relative;
}

.delimiterContainer {
  position: absolute;
  bottom: 0;
  height: 50px;
}
</style>
