<template>
  <v-container class="px-0">
    <template v-for="(item, index) in categoryInfo">
      <ShopCategoryListItem
        @click="onItemClick"
        :item="item"
        :key="item.id"
        :isSearchResultItem="true"
      />
      <v-divider
        v-if="$vuetify.breakpoint.smAndDown && index != categoryInfo.length - 1"
        :key="`divider-${item.id}`"
      />
    </template>
  </v-container>
</template>

<script>
import { ROUTE_NAMES } from '@/modules/product/shop/route';
import ShopCategoryListItem from '@/modules/product/shop/features/ShopCategory/ShopCategoryListItem';
export default {
  name: 'ShopCategoryList',
  components: { ShopCategoryListItem },
  props: {
    categoryInfo: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    onItemClick(item) {
      this.$router.push({
        name: ROUTE_NAMES.SHOP_PRODUCT_DETAILS,
        params: {
          id: this.$route.params.id,
          offeringId: item.id,
        },
      });
    },
  },
};
</script>
