<script>
import ShopMixins from './ShopMixins.vue';
import _transform from 'lodash/transform';
export default {
  name: 'OfferingListingMixin',
  mixins: [ShopMixins],
  methods: {
    addCorrespondingOfferingsToCategory(c, offs) {
      let newCategory = { ...c };
      newCategory.offerings = [];
      const offering = {};
      newCategory.offeringTypeIds.forEach((cId, i) => {
        offs.forEach((off, i) => {
          if (off.offeringTypeIds.includes(cId)) {
            offering[off.id] = off;
          }
        });
      });
      newCategory.offerings = Object.values(offering);
      return newCategory;
    },

    formatList(arr) {
      if (arr.length) {
        return arr.map((item) => ({
          ...item,
          offeringTypeIds: item.offeringTypes.map((i) => i.offeringTypeId),
        }));
      }
    },
  },

  computed: {
    allCategoryOfferings() {
      return this.$store.getters[
        `${this.storeModuleName}/allCategoryOfferings`
      ];
    },

    formattedCategoryOfferings() {
      if (this.allCategoryOfferings && this.allCategoryOfferings.length) {
        return this.formatList(this.allCategoryOfferings);
      }
      return [];
    },

    formattedCategories() {
      if (this.categories.categories && this.categories.categories.length) {
        return this.formatList(this.categories.categories);
      }
      return [];
    },

    categoryWithAssociatedOfferings() {
      if (
        this.formattedCategories &&
        this.formattedCategoryOfferings &&
        this.formattedCategories.length &&
        this.formattedCategoryOfferings.length
      ) {
        return this.formattedCategories.map((category) => {
          return this.addCorrespondingOfferingsToCategory(
            category,
            this.formattedCategoryOfferings
          );
        });
      }
      return [];
    },

    listingsAreLoading() {
      return this.$store.state[`${this.storeModuleName}`].categoryListings
        .isLoading;
    },

    hasListingsValue() {
      return this.$store.getters[`${this.storeModuleName}/hasListingsValue`];
    },
  },
};
</script>
