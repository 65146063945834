<template>
  <v-container class="pa-0">
    <v-row align="center">
      <v-col>
        <v-row no-gutters align="center">
          <v-col cols="12" sm="auto"
            ><span class="variants-title subtitle-1 font-weight-bold"
              >{{ offeringGroup.name }}
            </span></v-col
          >
          <v-col class="pl-sm-1" cols="12" sm="auto">
            <span
              v-if="shouldShowError && hasError"
              class="error-msg text-caption error--text"
            >
              {{
                $t('shop.min_selection', {
                  minSelection: this.offeringGroup.minSelection,
                })
              }}</span
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto">
        <v-chip small label :class="tagClass" class="px-2">
          <template v-if="tagClass === 'success'">
            <v-icon small color="#fff" class="mr-1">mdi-check</v-icon>
          </template>
          {{ tagText }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-list>
          <v-list-item-group>
            <template v-for="variant in offeringGroup.offeringVariants">
              <v-list-item
                class="px-0"
                :key="`variant-${variant.id}`"
                @click="onVariantClick(variant)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <span>{{ variant.name }}</span
                    ><span v-if="variant.price.value > 0">
                      - {{ $t('shop.add') }}
                      {{
                        variant.price.value
                          | currency(variant.price.baseCurrency)
                      }}</span
                    >
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox
                    @click.prevent="onVariantClick(variant)"
                    v-model="selection"
                    :value="variant.id"
                  ></v-checkbox>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="`divider-${variant.id}`" v-if="!isProductPage" />
            </template>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'OfferingVariantsSelectMultiple',
  data() {
    return {
      selection: this.value ? this.value.map((v) => v.id) : [],
      shouldShowError: false,
    };
  },
  props: {
    isProductPage: {
      type: Boolean,
      default: false,
    },
    offeringGroup: {
      type: Object,
      default: () => ({}),
    },
    showError: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onVariantClick(variant) {
      const foundIndex = this.selection.findIndex(
        (selection) => selection === variant.id
      );
      if (foundIndex > -1) {
        this.selection.splice(foundIndex, 1);
      } else if (!this.hasSelectedMax) {
        this.selection.push(variant.id);
      }
    },
  },
  watch: {
    showError: {
      immediate: true,
      handler(showError) {
        this.shouldShowError = showError;
      },
    },
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.selection = value.map((v) => v.id);
        }
      },
    },
    selection: {
      immediate: true,
      handler(selection) {
        const selectedVariants = this.offeringGroup.offeringVariants.filter(
          (oVariant) => selection.includes(oVariant.id)
        );
        this.$emit('select', selectedVariants);
      },
    },
  },
  computed: {
    hasError() {
      return this.selection.length < this.offeringGroup.minSelection;
    },
    hasSelectedMax() {
      return this.selection.length >= this.offeringGroup.maxSelection;
    },
    isRequired() {
      return this.offeringGroup.type === 'required';
    },
    tagText() {
      return this.isRequired
        ? this.$t('shop.variants.min_selection_required', {
            min: this.offeringGroup.minSelection,
          })
        : this.offeringGroup.maxSelection > 0
        ? this.$t('shop.variants.max_selection', {
            max: this.offeringGroup.maxSelection,
          })
        : this.$t('shop.variants.optional');
    },
    tagClass() {
      return this.isRequired
        ? this.selection.length >= this.offeringGroup.minSelection
          ? 'success'
          : 'warning-secondary'
        : 'tertiary';
    },
  },
};
</script>

<style scoped lang="scss">
.variants-title {
  color: var(--v-grey9-base);
}
.error-msg {
  position: absolute;
  @media (min-width: map-get($grid-breakpoints, sm)) {
    position: inherit;
  }
}
</style>
