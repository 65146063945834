<template>
  <v-dialog
    v-model="dialogModel"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    content-class="shop-offering-details-dialog"
    scrollable
    max-width="600px"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card v-if="offeringDetails" style="overflow: hidden">
      <v-card-title class="pa-2">
        <v-container fluid class="pa-0">
          <v-row no-gutters align="center">
            <v-col cols="1">
              <v-btn icon @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="10">
              <span class="d-block text-center dialog-name-title">
                {{ offeringDetails.name }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="max-height: 90%">
        <ImageSliderComponent
          :max-height="maxImgHeight"
          :image-urls="offeringDetails.images"
        />
        <span class="d-block name-title py-4">{{ offeringDetails.name }}</span>
        <template v-if="!(hasVariants && offeringDetails.price.value === 0)">
          <span class="current-price">
            {{
              offeringDetails.price.value
                | currency(offeringDetails.price.baseCurrency)
            }}
          </span>
        </template>

        <template v-if="hasOriginalPrice">
          <span class="pl-2 original-price">
            {{ originalPrice.body | currency(price.baseCurrency) }}
          </span>
        </template>

        <template v-if="hasReducedAmount">
          <span class="d-block red--text size14-weight600 pt-1">
            {{ $t('shop.you_save') }}
            {{ reducedAmount.body | currency(price.baseCurrency) }}
          </span>
        </template>

        <template v-if="offeringDetails.description">
          <p class="mt-3">
            {{ offeringDetails.description }}
          </p>
        </template>

        <template v-if="containsInformation">
          <p
            class="pt-2"
            v-for="(info, key) in filteredOfferingsInformation"
            :key="key"
          >
            <template v-if="info.title">
              <span class="d-block font-weight-bold">
                {{ info.title }}
              </span>
            </template>

            <template v-if="info.body">
              <span class="d-block">{{ info.body }}</span>
            </template>
          </p>
        </template>

        <template v-if="hasVariants">
          <OfferingVariantsGroups
            :offering-groups="offeringDetails.offeringGroups"
            :show-error="showVariantsError"
            :variants-selection="variantsSelection"
            @change="onVariantsChange"
          />
        </template>
      </v-card-text>
      <v-divider />
      <v-card-actions
        class="card-actions pa-4"
        :class="{ 'pb-0 px-0': $vuetify.breakpoint.smAndDown }"
      >
        <v-container class="pa-0">
          <v-row no-gutters align="center" justify="space-between">
            <v-col
              cols="4"
              sm="3"
              :class="{ 'pb-4 pl-3': $vuetify.breakpoint.smAndDown }"
            >
              <NumberInput
                v-model="quantityModel"
                :min="1"
                :max="maxQuantity"
              />
            </v-col>
            <v-col
              cols="auto"
              sm="3"
              :class="{ 'pb-4 pr-3': $vuetify.breakpoint.smAndDown }"
            >
              <span class="price text-center d-inline-block">
                {{ price.value | currency(price.baseCurrency) }}
              </span>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn
                data-cy="add-to-cart-button"
                large
                :x-large="$vuetify.breakpoint.smAndDown"
                tile
                depressed
                block
                :disabled="!isOpen"
                @click="onSubmit"
                color="primary"
              >
                {{ actionButtonText }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import NumberInput from '@/core/components/NumberInput';
import OfferingVariantsGroups from '@/modules/product/shop/features/OfferingVariants/OfferingVariantsGroups';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import ImageSliderComponent from '@/modules/common/components/ImageSliderComponent';
import ShopMixins from '@/modules/product/shop/mixins/ShopMixins';
import HotJarMixins from '@/core/mixins/HotJarMixins';

export default {
  name: 'ShopOfferingDetailsDialog',
  mixins: [GoogleTagManagerMixins, ShopMixins, HotJarMixins],
  components: { ImageSliderComponent, OfferingVariantsGroups, NumberInput },
  props: {
    maxQuantity: {
      type: Number,
      default: Number.MAX_VALUE,
    },
    quantity: {
      type: Number,
      default: 0,
    },
    priceValue: {
      type: Number,
      default: 0,
    },
    priceBaseCurrency: {
      type: String,
      value: '',
    },
    isDialogOpen: {
      type: Boolean,
      default: false,
    },
    offeringDetails: {
      type: Object,
      default: () => {},
    },
    showVariantsError: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    variantsSelection: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      dialogModel: this.isDialogOpen,
      quantityModel: this.quantity,
    };
  },

  methods: {
    close() {
      this.dialogModel = false;
      this.$emit('close');
    },

    onVariantsChange(variantsSelection) {
      this.$emit('change:variants', variantsSelection);
    },

    onSubmit() {
      this.$emit('submit');
    },

    pushItemViewToAnalytics() {
      let items = [
        {
          item_name: this.offeringDetails?.name,
          item_id: this.offeringDetails?.id,
          price: this.offeringDetails?.price?.value,
          quantity: this.quantity,
        },
      ];
      this.pushProductView(items);
      this.pushHotJarAnalytics('view_item');
    },
  },

  computed: {
    actionButtonText() {
      return this.isEditing
        ? this.$t('shop.save_item')
        : this.$t('shop.add_to_order');
    },

    filteredOfferingsInformation() {
      const bList = ['original_price', 'reduced_amount'];
      return this.offeringDetails.information?.filter(
        (info) => bList.indexOf(info.title) < 0
      );
    },

    hasOriginalPrice() {
      return Boolean(this.originalPrice);
    },

    originalPrice() {
      return this.offeringDetails.information?.find(
        (info) => info.title === 'original_price'
      );
    },

    hasReducedAmount() {
      return Boolean(this.reducedAmount);
    },

    reducedAmount() {
      return this.offeringDetails.information?.find(
        (info) => info.title === 'reduced_amount'
      );
    },

    hasVariants() {
      return Boolean(this.offeringDetails?.offeringGroups?.length > 0);
    },

    maxImgHeight() {
      return this.$vuetify.breakpoint.smAndDown ? 224 : 250;
    },

    price() {
      return {
        value: this.priceValue,
        baseCurrency: this.priceBaseCurrency,
      };
    },

    containsInformation() {
      return (
        !!this.offeringDetails &&
        !!this.offeringDetails.information &&
        !!this.offeringDetails.information.length
      );
    },
  },

  watch: {
    quantityModel: {
      immediate: true,
      handler(quantityModel) {
        this.$emit('change:quantity', quantityModel);
      },
    },

    quantity: {
      immediate: true,
      handler(quantity) {
        this.quantityModel = quantity;
      },
    },

    isDialogOpen: {
      immediate: true,
      handler(isDialogOpen) {
        this.dialogModel = isDialogOpen;
        if (!!isDialogOpen && !!this.offeringDetails) {
          this.pushItemViewToAnalytics();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.shop-offering-details-dialog {
  .dialog-name-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .name-title {
    @include font-size(24, 150, 300);
    color: var(--v-grey9-base);
  }

  .price {
    font-weight: 600;
    font-size: 18px;
    color: var(--v-grey9-base);
  }

  .card-actions {
    box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.12);
  }

  .original-price {
    font-size: 14px;
    font-weight: 500;
    color: var(--v-grey7-base);
    text-decoration: line-through;
  }

  .current-price {
    font-size: 20px;
    font-weight: 600;
    color: var(--v-grey8-base);
  }
}
</style>
