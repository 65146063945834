import Pusher from 'pusher-js';
import Vue from 'vue';

let instance;

export const usePusher = () => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        pusher: null,
      };
    },
    methods: {
      initialize(pusher_api_key) {
        this.pusher = new Pusher(pusher_api_key, {
          cluster: 'us2',
        });
      },
      subscribe(channelName) {
        if (channelName) {
          return this.pusher.subscribe(channelName);
        }
        return null;
      },
    },
  });
  return instance;
};

export const PusherPlugin = {
  install(Vue, options) {
    Vue.prototype.$pusher = usePusher(options);
  },
};
