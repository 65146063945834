<template>
  <v-container fluid class="py-0 px-4">
    <v-alert
      v-if="!isInstantConfirmation"
      class="mb-1 mx-4 mt-4"
      :value="!isInstantConfirmation"
      type="info"
      outlined
      text
      color="#306CC5"
    >
      <span class="main-message">
        <b>{{ $t('product.order_in_process') }}</b
        >{{ $t('product.order_card_wont_charge') }}
      </span>
    </v-alert>
    <v-row data-cy="order-confirmation-status" v-else align="center" dense>
      <v-col cols="auto">
        <v-icon large color="success">mdi-checkbox-marked-circle</v-icon>
      </v-col>
      <v-col cols="auto">
        <h1 class="order-placed-title">{{ $t('shop.order_placed') }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <span
        v-html="$t('shop.order_placed_description', { email: customer.email })"
      >
      </span>
    </v-row>
    <v-card-title
      data-cy="order-confirmation-status"
      class="pa-0 px-5 px-md-0 mt-5 mt-md-0 font-weight-bold"
    >
      <template v-if="!isInstantConfirmation">
        <v-icon size="31" color="#306CC5" class="mr-2">mdi-alert</v-icon>
        <span class="mb-1 pending-text">{{ $t('product.order_pending') }}</span>
      </template>
    </v-card-title>
    <v-row>
      <v-col cols="12" class="pa-0">
        <ShopCheckoutOrderDetails
          :customer="customer"
          :order-reference-id="orderReferenceId"
          :supplier-reference-id="supplierReferenceId"
          :order-date="createdOn"
          :credit-card-last-digits="creditCardLastDigits"
          :is-credit-card="isPaymentCreditCard"
          :total="total"
          :base-currency="baseCurrency"
          :paymentType="paymentMethodType"
        />
      </v-col>
    </v-row>
    <v-row :justify="mobile ? 'center' : 'end'">
      <v-col cols="auto" class="pa-0">
        <v-btn :text="mobile" depressed color="primary" @click="goHome">{{
          $t('shop.back_to_shop')
        }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import { ROUTE_NAMES } from '@/modules/product/shop/route';
import ShopCheckoutOrderDetails from '@/modules/product/shop/features/ShopCheckoutConfirmation/ShopCheckoutOrderDetails';
import ShopOrderMixins from '@/modules/product/shop/mixins/ShopOrderMixins';

export default {
  name: 'ShopCheckoutConfirmationContainer',
  components: { ShopCheckoutOrderDetails },
  mixins: [GoogleTagManagerMixins, ShopOrderMixins],
  props: {
    lastOrder: Array,
    total: Number,
    flightInfo: Object,
    customer: Object,
    orderReferenceId: String,
    supplierReferenceId: String,
    createdOn: String,
    baseCurrency: String,
    creditCardLastDigits: String,
    showTotal: Boolean,
    mobile: Boolean,
  },
  created() {
    this.pushToAnalytics();
  },
  methods: {
    goHome() {
      this.$router.push({ name: ROUTE_NAMES.SHOP_LANDING });
      this.clearCart();
    },
    pushToAnalytics() {
      let itemsList = [];
      for (let item of this.lastOrder) {
        let product = {
          item_name: item.offering.name,
          item_id: item.offering.id,
          price: item.offering.price.value,
          quantity: item.quantity,
        };
        itemsList.push(product);
      }

      let purchase = {
        transaction_id: this.orderReferenceId,
        affiliation: this.shopTitle,
        value: this.total,
        currency: this.baseCurrency,
        items: itemsList,
      };
      this.pushPurchase(purchase);
    },
  },
  computed: {
    isInstantConfirmation() {
      return (
        this.hasCheckedOutOrder && this.checkedOutOrder.status === 'Confirmed'
      );
    },
    isPaymentCreditCard() {
      return this.paymentMethodType === 'CreditCard';
    },
    paymentMethodType() {
      return this.hasCheckedOutOrder
        ? this.checkedOutOrder.payments[0].paymentMethodType
        : null;
    },
    displayedTotal() {
      return parseFloat(this.total).toFixed(2);
    },
  },
};
</script>

<style scoped lang="scss">
.order-placed-title {
  font-weight: 300;
  font-size: 20px;
  color: var(--v-success-darken3) !important;
  filter: brightness(150%);
}
</style>
